import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import router from './router'
import registerComponents from './setup/registerComponents'
import registerDirectives from "./setup/registerDirectives"
import registerFilters from './setup/registerFilters'
import store from './composables/store'

// CSS
import 'primeicons/primeicons.css'

const app = createApp(App);


// global
registerComponents(app);
registerDirectives(app);
registerFilters(app);
app.provide('store', store);
app.provide('rootUrl', process.env.VUE_APP_ROOT_URL);
app.provide('apiUrl', process.env.VUE_APP_API_URL);
app.provide('apiHost', process.env.VUE_APP_API_HOST);

// Errorhandling
app.config.errorHandler = (err) => {
    console.error(err);
};


// usings
app.use(PrimeVue,
    {
        locale: {
            startsWith: 'Starts with',
            contains: 'Contains',
            notContains: 'Not contains',
            endsWith: 'Ends with',
            equals: 'Equals',
            notEquals: 'Not equals',
            noFilter: 'No Filter',
            lt: 'Less than',
            lte: 'Less than or equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            dateIs: 'Date is',
            dateIsNot: 'Date is not',
            dateBefore: 'Date is before',
            dateAfter: 'Date is after',
            clear: 'Ryd',
            apply: 'Anvend',
            matchAll: 'Match alt',
            matchAny: 'Match enhver',
            addRule: 'Tilføj regel',
            removeRule: 'Fjern regel',
            accept: 'Ja',
            reject: 'Nej',
            choose: 'Vælg',
            upload: 'Upload',
            cancel: 'Fortryd',
            completed: 'Completed',
            pending: 'Afventer',
            dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
            dayNamesShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tors', 'Fre', 'Lør'],
            dayNamesMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
            monthNames: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
            chooseYear: 'Vælg år',
            chooseMonth: 'Vælg månede',
            chooseDate: 'Vælg dato',
            prevDecade: 'Previous Decade',
            nextDecade: 'Next Decade',
            prevYear: 'Previous Year',
            nextYear: 'Next Year',
            prevMonth: 'Previous Month',
            nextMonth: 'Next Month',
            prevHour: 'Previous Hour',
            nextHour: 'Next Hour',
            prevMinute: 'Previous Minute',
            nextMinute: 'Next Minute',
            prevSecond: 'Previous Second',
            nextSecond: 'Next Second',
            am: 'am',
            pm: 'pm',
            today: 'Idag',
            weekHeader: 'Uge',
            firstDayOfWeek: 0,
            dateFormat: 'dd-mm-yy',
            weak: 'Weak',
            medium: 'Medium',
            strong: 'Strong',
            passwordPrompt: 'Enter a password',
            emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
            searchMessage: '{0} results are available',
            selectionMessage: '{0} items selected',
            emptySelectionMessage: 'No selected item',
            emptySearchMessage: 'Vi kunne desværre ikke finde et match',
            emptyMessage: 'No available options',
            aria: {
                "trueLabel": "True",
                "falseLabel": "False",
                "nullLabel": "Not Selected",
                "star": "1 star",
                "stars": "{star} stars",
                "selectAll": "All items selected",
                "unselectAll": "All items unselected",
                "close": "Close",
                "previous": "Previous",
                "next": "Next",
                "navigation": "Navigation",
                "scrollTop": "Scroll Top",
                "moveTop": "Move Top",
                "moveUp": "Move Up",
                "moveDown": "Move Down",
                "moveBottom": "Move Bottom",
                "moveToTarget": "Move to Target",
                "moveToSource": "Move to Source",
                "moveAllToTarget": "Move All to Target",
                "moveAllToSource": "Move All to Source",
                "pageLabel": "{page}",
                "firstPageLabel": "First Page",
                "lastPageLabel": "Last Page",
                "nextPageLabel": "Next Page",
                "previousPageLabel": "Previous Page",
                "rowsPerPageLabel": "Rows per page",
                "jumpToPageDropdownLabel": "Jump to Page Dropdown",
                "jumpToPageInputLabel": "Jump to Page Input",
                "selectRow": "Row Selected",
                "unselectRow": "Row Unselected",
                "expandRow": "Row Expanded",
                "collapseRow": "Row Collapsed",
                "showFilterMenu": "Show Filter Menu",
                "hideFilterMenu": "Hide Filter Menu",
                "filterOperator": "Filter Operator",
                "filterConstraint": "Filter Constraint",
                "editRow": "Row Edit",
                "saveEdit": "Save Edit",
                "cancelEdit": "Cancel Edit",
                "listView": "List View",
                "gridView": "Grid View",
                "slide": "Slide",
                "slideNumber": "{slideNumber}",
                "zoomImage": "Zoom Image",
                "zoomIn": "Zoom In",
                "zoomOut": "Zoom Out",
                "rotateRight": "Rotate Right",
                "rotateLeft": "Rotate Left"
            }
        }

    });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.mount('#app');