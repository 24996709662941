<template>
    <div class="page-login">
        <div class="wrapper mb-4 p-2">
            <img src="../assets/skoleadmin-text-purple.svg" class="logo" />
            <div class="text-center pt-1 pb-6">
                <small class="clickable">Har du ikke en konto? Tilmeld dig gratis</small>
            </div>
            <h2 class="text-center my-4">Vælg hvordan du gerne vil logge ind</h2>
            <div class="grid">
                <div class="col-12 md:col-6" @click="fn.gotoNemIdLogin">
                    <div class="box text-center clickable">
                        <img src="../assets/nemid.png" class="p-5" style="max-width:200px;" />
                    </div>
                    <div class="text-center p-2">MitID</div>
                </div>
                <div class="col-12 md:col-6">
                    <div class="box text-center clickable disabled">
                        <img src="../assets/skoleadmin-text-purple.svg" class="p-3" style="max-width:119px;" />
                    </div>
                    <div class="text-center p-2">Skoleadmin ID</div>
                </div>
            </div>

        </div>
    </div>

</template>


<script>
    import { inject, reactive } from 'vue';
    import { useRoute } from 'vue-router';

    export default {
        setup() {
            const route = useRoute();
            const apiUrl = inject('apiUrl');
            const vm = reactive({

            });
            const fn = {
                gotoNemIdLogin: () => {
                    window.location.href = apiUrl + 'authentication/authenticate?returnUrl=' + route.query.returnUrl;
                }
            };

            return {
                vm, fn

            }
        }
    }
</script>
<style scoped>
    .page-login {
        background-color: #f4f4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
    }

    .wrapper {
        max-width: 600px;
        width: 100%;
    }

        .wrapper .logo {
            display: block;
            margin: 0 auto;
            max-width: 250px;
        }

</style>