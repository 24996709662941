<template>
    <div class="page-admin-archive">
        <page-header headline="Arkiv"></page-header>
        <div class="content-wrapper">
            <div class="main-content">
                <Message severity="info" :closable="false" class="mt-0 mb-5">
                    Arkivet er sidste trin inden permanent sletning og det sted, hvor du kan fremsøge dokumenter og samtykker ved hjælp af CPR-nummer eller fuldt navn, hvis du får brug for dem til dokumentation. Hvor længe et dokument eller samtykke opbevares i arkivet, styres under Indstillinger > Sletteregler (som standard opbevares de i 60 måneder). <router-link :to="{ name: 'AdminHelp', hash: '#retention-rules' }">Læs mere on sletteregler her</router-link>

                </Message>
                <div class="p-inputgroup">
                    <InputText type="text" placeholder="Indtast CPR-nummer eller fuldt navn" v-model="vm.query" @keyup.enter="fn.search" /><Button @click="fn.search" label="Søg" />
                </div>
                <div class="mt-4">
                    <div v-if="vm.indexCards && vm.indexCards.length">
                        <div v-for="indexCard in vm.indexCards" :key="indexCard.id">
                            <h3 class="mb-0">{{indexCard.name}}</h3>
                            <div v-if="indexCard.archivedItems">
                                <DataTable :value="indexCard.archivedItems" dataKey="id" stripedRows>
                                    <Column field="name" header="Navn">
                                        <template #body="slotProps">
                                            <button type="button" class="p-link clickable" @click="fn.getDocument(slotProps.data)">
                                                <strong>{{slotProps.data.name}}</strong>
                                            </button>
                                        </template>
                                    </Column>
                                    <Column field="updated" header="Slettet" headerStyle="width:200px">
                                        <template #body="slotProps">
                                            {{$filters.formatDate(slotProps.data.created)}}
                                        </template>
                                    </Column>
                                    <Column field="updated" header="Udløbsdato" headerStyle="width:200px">
                                        <template #body="slotProps">
                                            {{$filters.formatDate(slotProps.data.expiresAt)}}
                                        </template>
                                    </Column>
                                    <Column :exportable="false" class="text-center" style="width:80px">
                                        <template #body="slotProps">
                                            <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggle($event, indexCard, slotProps.data)" />
                                            <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="extraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                            <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <Message v-if="vm.indexCards && vm.indexCards.length === 0" severity="info" :closable="false">
                        Vi kunne deværre ikke finde nogen resultater
                    </Message>
                </div>
            </div>
        </div>
        <ConfirmDialog group="confirmDelete" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.currentItem">Er du sikker på at du vil slette <b>{{vm.currentItem.name}}</b>?</div>
            <Message severity="info" :closable="false">Dokumentet/samtykket vil blive slettet permanent og kan ikke genskabes.</Message>
        </ConfirmDialog>
    </div>
</template>

<script setup>
    import { inject, ref, reactive } from 'vue';
    import api from './../../composables/api';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from "primevue/useconfirm";

    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const confirm = useConfirm();

    const vm = reactive({
        indexCards: null,
        query: '',
        busy: false
    });

    const extraActions = ref([
        {
            label: 'Se dokument',
            icon: 'pi pi-eye',
            command: () => {
                fn.getDocument(vm.currentItem);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Hent dokument',
            icon: 'pi pi-download',
            command: () => {
                fn.getDocument(vm.currentItem, true);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                confirm.require({
                    group: 'confirmDelete',
                    header: 'Bekræft slet',
                    acceptLabel: 'Ja, slet',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, fortryd',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => {
                        fn.delete();
                    }
                });
                vm.visibleMenuId = null;
            }
        }
    ]);
    const fn = {};
    fn.search = () => {
        if (vm.query && vm.query.length > 3) {
            vm.indexCards = '';
            api.get(apiUrl + 'archive?query=' + vm.query).then((response) => {
                vm.indexCards = response;
            }).catch((error) => {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
            });
        } else {
            toast.add({ severity: 'info', summary: 'Søgeinput skal være længere end 3 tegn', detail: '', life: 3000 });
        }
    }
    fn.getDocument = (archivedItem, forceDownload) => {
        if (archivedItem && archivedItem.url) {
            if (forceDownload) {
                var link = document.createElement('a');
                link.href = archivedItem.url;
                link.click();
            } else {
                window.open(archivedItem.url, '_blank');
            }
        }
    };

    fn.delete = () => {
        api.del(apiUrl + 'archive/' + vm.currentIndexCard.id + '/items/' + vm.currentItem.id).then(() => {
            const index = vm.currentIndexCard.archivedItems.findIndex((obj => obj.id === vm.currentItem.id));
            vm.currentIndexCard.archivedItems.splice(index, 1);

            toast.add({ severity: 'success', summary: 'Gennemført', detail: vm.currentItem.name + ' slettet', life: 3000 });
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        });
    }


    fn.toggle = (event, indexCard, item) => {
        vm.currentIndexCard = indexCard;
        vm.currentItem = item;
        vm.visibleMenuId = vm.currentItem.id === vm.visibleMenuId ? null : vm.currentItem.id;
    };

</script>

<!--<style>
    .page-admin-archive .p-datatable-thead {
        display: none;
    }
</style>-->
