// GLOBAL COMPONENTS

export default function (app) {
    // Internal Components
    app.component('page-header', require('./../components/PageHeader.vue').default);
    app.component('nav-bar', require('./../components/NavBar.vue').default);
    app.component('template-builder', require('../components/TemplateBuilder.vue').default);
    app.component('consent-creator', require('../components/ConsentCreator.vue').default);
    app.component('document-sender', require('../components/DocumentSender.vue').default);
    app.component('document-statistics', require('../components/DocumentStatistics.vue').default);
    app.component('e-signature', require('../components/ESignature.vue').default);
    app.component('stats-half-doughnut', require('../components/StatsHalfDoughnut.vue').default);
    app.component('render-document-html', require('../components/RenderDocumentHtml.vue').default);
    app.component('student-grant-form', require('../components/StudentGrantForm.vue').default);
    app.component('master-data-input', require('../components/MasterData.vue').default);
    app.component('confirm-member-data', require('../components/ConfirmMemberData.vue').default);
    app.component('pdf-viewer', require('../components/PdfViewer.vue').default);
    app.component('pincode', require('../components/Pincode.vue').default);
    app.component('assets-upload', require('../components/AssetsUpload.vue').default);

    // Prime Vue Components
    app.component('Accordion', require('primevue/accordion').default);
    app.component('AccordionTab', require('primevue/accordiontab').default);
    app.component('AutoComplete', require('primevue/autocomplete').default);
    app.component('Avatar', require('primevue/avatar').default);
    app.component('Badge', require('primevue/badge').default);
    app.component('Button', require('primevue/button').default);
    app.component('Calendar', require('primevue/calendar').default);
    app.component('Chart', require('primevue/chart').default);
    app.component('Checkbox', require('primevue/checkbox').default);
    app.component('Column', require('primevue/column').default);
    //app.component('ConfirmDialog', require('../overrides/primevue/confirmdialog/sfc').default);
    app.component('ConfirmDialog', require('../overrides/primevue/confirmdialog-v.3.29.1/sfc').default);
    app.component('DataTable', require('primevue/datatable').default);
    app.component('Dialog', require('primevue/dialog').default);
    //app.component('FileUpload', require('../overrides/primevue/fileupload').default);
    app.component('FileUpload', require('../overrides/primevue/fileupload-v.3.29.1/sfc').default);
    app.component('Divider', require('primevue/divider').default);
    app.component('Image', require('primevue/image').default);
    app.component('InlineMessage', require('primevue/inlinemessage').default);
    app.component('InputSwitch', require('primevue/inputswitch').default);
    app.component('InputText', require('primevue/inputtext').default);
    app.component('InputMask', require('primevue/inputmask').default);
    app.component('InputNumber', require('primevue/inputnumber').default);
    app.component('Knob', require('primevue/knob').default);
    app.component('Listbox', require('primevue/listbox').default);
    app.component('Menu', require('primevue/menu').default);
    app.component('Menubar', require('primevue/menubar').default);
    app.component('Message', require('primevue/message').default);
    app.component('MultiSelect', require('primevue/multiselect').default);
    app.component('ProgressBar', require('primevue/progressbar').default);
    app.component('ProgressSpinner', require('primevue/progressspinner').default);
    app.component('RadioButton', require('primevue/radiobutton').default);
    app.component('SelectButton', require('primevue/selectbutton').default);
    app.component('Sidebar', require('primevue/sidebar').default);
    app.component('Skeleton', require('primevue/skeleton').default);
    app.component('SplitButton', require('primevue/splitbutton').default);
    app.component('TabPanel', require('primevue/tabpanel').default);
    app.component('TabView', require('primevue/tabview').default);
    app.component('Tag', require('primevue/tag').default);
    app.component('Textarea', require('primevue/textarea').default);
    app.component('Toast', require('primevue/toast').default);
    app.directive('Toolbar', require('primevue/toolbar').default);
    app.directive('Tooltip', require('primevue/tooltip').default);
    app.component('Tree', require('primevue/tree').default);

}