<template>
    <div class="page-admin-login">
        LOGIN
    </div>
</template>

<script>
    import { inject, reactive } from 'vue';
    //import api from '../../composables/api';
    //import gqlBuilder from '../../composables/gql-builder';

    export default {
        setup() {
            const apiUrl = inject('apiUrl');
            const vm = reactive({
                customerInfo: {},
                apiHost: inject('apiHost')
            });
            const fn = {};

            console.log(apiUrl);

            return {
                vm, fn
            };
        }
    }
</script>

<style scoped>
</style>