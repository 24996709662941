<template>
    <div class="page-admin-help">
        <page-header headline="Hjælp, ofte stillede spørgsmål"></page-header>
        <div class="content-wrapper">
            <div class="main-content mb-5">
                <h4>GDPR</h4>
                <Accordion>
                    <AccordionTab header="Vigtig info ift indsamling af data, GDPR">
                        <div class="px-3">
                            <p>
                                Vi har observeret at efterskolerne gør ting forskelligt 😊 Det gælder også for hvornår, hvordan og hvor meget data man indsamler.
                                Vi vil gerne hjælpe med at optimere den proces, men <em>Rom blev ikke bygget på en dag</em>, så for at kunne understøtte jeres behov og arbejdsrutiner her og nu, har vi lavet et par opdateringer, så I kan fortsætte som altid, men stadig sikre at GDPR overholdes.
                            </p>
                            <p>
                                <strong>Elevens stamdata</strong><br />
                                Vi har oprettet et nyt modul, stamdata, der kan vælges i dokumentbyggeren. Her kan man nemt vælge hvilke felter, der skal vises samt hvilke der skal være udfyldte. Det kunne være cpr-nummer, navn, adresse etc. De nuværende data på eleven vises i de valgte felter og opdateres automatisk i Skoleadmin, hvis der laves ændringer.
                                Elevens stamdata vil blive vist i dokumentet inklusiv elevens cpr-nummer, hvis feltet er valgt, og denne data vil være synlig for underskrivere. Det må den gerne, da det er eleven, dokumentet drejer sig om.
                            </p>
                            <p>
                                <strong>Relationernes stamdata</strong><br />
                                Hvis man gerne vil have bekræftet og eventuelt opdateret relationernes stamdata, kan man på underskrifts modulet slå det til ved at vælge "Bekræft stamdata".
                                Hver relation vil efter endt underskrift blive bedt om at bekræfte deres stamdata. Denne stamdata vil ikke blive vist i den efterfølgende kopi af dokumentet eller være synlig for andre end relationen selv. Stamdata vil automatisk blive ajourført i Skoleadmin.
                                På denne måde sikrer vi, at vi ikke deler personoplysninger med anden part, og at relevante data godkendes og udfyldes af rette vedkommende.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Gode råd ift GDPR og indhentning af data">
                        <div class="px-3">
                            <p>
                                Man må kun indhente data man har brug for på det givne tidspunkt man indhenter det.
                            </p>
                            <p>
                                Et eksempel kunne være en venteliste. Når eleven tilmeldes ventelisten, vil der ofte ikke være behov for at indhente CPR-nummer eller adresse. Disse oplysninger bliver først relevante, når og hvis der skal laves en skolekontrakt. Derimod vil det være relevant med kontaktoplysninger såsom e-mail og telefon samt eventuelle præferencer for specifikke linjer, køn osv.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Gode råd ift GDPR og oprydning af data">
                        <div class="px-3">
                            <p>
                                Her gælder de samme regler som for indhentning af data.
                            </p>
                            <p>
                                Det vil sige, at man er forpligtet til at rydde op og fjerne data, så snart data ikke længere er relevante. Det er derfor vigtigt, at man som skole udformer slettepolitikker, der sikrer, at man altid kun har relevante data liggende. Skoleadmin hjælper med dette, og vi udbygger løbende systemet, så I kan konfigurere og automatisere disse politikker.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Hvad er følsomme personoplysninger?">
                        <div class="px-3">
                            <p>
                                Følsomme oplysninger er oplysninger om:
                            </p>
                            <ul class="ml-5"><li>Race og etnisk oprindelse</li><li>Politisk overbevisning</li><li>Religiøs eller filosofisk overbevisning</li><li>Fagforeningsmæssige tilhørsforhold</li><li>Genetiske data</li><li>Biometriske data med henblik på entydig identifikation</li><li>Helbredsoplysninger</li><li>Seksuelle forhold eller seksuel orientering</li></ul>
                            <p>
                                CPR-nummeret er ikke defineret som en følsom oplysning, men kan bruges til identitetstyveri, hvorfor det er en rigtig god ide at passe godt på det. Derfor obfuskerer vi fx også CPR-nummeret i statsstøtteskemaet.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Hvad er ikke-følsomme personoplysninger (almindelige personoplysninger)?">
                        <div class="px-3">
                            <p>
                                Almindelige personoplysninger omfatter alle de oplysninger, der ikke er nævnt under følsomme personoplysninger. Det kunne være navn og adresse.
                            </p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-6">Afsendelse (Send dokument)</h4>
                <Accordion>
                    <AccordionTab header="Hvem skal underskrive?">
                        <p>I underskriftsmodulen har man mulighed for at vælge, hvem der skal underskrive. Dette kan enten være alle, kun eleven eller kun relationerne.</p>
                        <p>Hvis 'alle' er valgt, kræves det, at både eleven og alle relationer underskriver, når man sender et dokument ud. Ved statsstøtteskemaet er proceduren lidt anderledes. Her bestemmes underskrivere først, når data er udfyldt, hvilket kan betyde, at det ikke nødvendigvis er alle relationer, og der kan tilføjes flere, f.eks. en ny samlever.</p>
                    </AccordionTab>
                    <AccordionTab header="Hvordan sender jeg et dokument?">
                        <p>Når et dokument er publiseret, kan det sendes ved at trykke på knappen 'Send' i menuen til venstre:</p>
                        <p><Button label="Send" icon="pi pi-envelope" class="p-button-lg p-button-secondary" style="width:175px" /></p>
                        <p>Derefter vælger man det ønskede dokument og angiver modtagerne. <br /><br />Bemærk, at efter valg af dokument, kan man vælge kun at se de elever, der endnu ikke har modtaget det, ved at vælge 'Vis kun elever, der ikke allerede har modtaget dokumentet'. <br />Man kan se eleverne ved at folde et hold ud ved at klikke på <i class="pi pi-chevron-right mx-2"></i></p>
                    </AccordionTab>
                    <AccordionTab header="Hvorfor er der nogle mails, der fejler ved afsendelse?">
                        <p>Der kan være mange grunde til, at en e-mail ikke kan leveres, men oftest skyldes det en tastefejl i e-mailadressen.<br />Hvis en e-mailadresse fejler, vil vi ikke sende flere mails til den e-mail.</p>
                        <p>Hvis det er tydeligt, at der er tale om en tastefejl, eller hvis man har fået fat i den korrekte e-mailadresse, kan man gå ind på eleven og rette e-mailadressen. Herefter vil den blive fjernet fra listen over mislykkede e-mails. Skoleadmin vil igen forsøge at sende mails til adressen.<br />Bemærk, at Skoleadmin ikke automatisk forsøger at gensende en mislykket e-mail igen. Det skal gøres manuelt.</p>
                    </AccordionTab>
                    <AccordionTab header="Hvordan retter jeg en mail adresse, der er fejlet?">
                        <p>Når man går ind på eleven, har man mulighed for at rette e-mailadressen både for eleven selv og for relationerne. Efter at den fejlede e-mailadresse er blevet rettet, vil den blive fjernet fra listen over mislykkede e-mails. Skoleadmin vil igen forsøge at sende mails til adressen.<br />Vær opmærksom på, at Skoleadmin ikke automatisk forsøger at gensende en mislykket e-mail igen. Dette skal gøres manuelt.</p>
                    </AccordionTab>
                    <AccordionTab header="En afsendelse er fejlet, men jeg kan ikke finde e-mail adressen på elev eller relation">
                        <p>Ved statsstøtteskemaet indtaster modtager selv e-mail adresse. Hvis den fejler, vil den vises i listen, men man kan ikke rette den, eller se den under detaljer på eleven.</p>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-4">Almindelig skabelon (Du bygger selv dit dokument)</h4>
                <Accordion>
                    <AccordionTab header="Byggeklods: Tekst">
                        <p>Med "Tekst" byggeklodsen kan du arbejde med tekst på samme måde som i andre tekstbehandlingsprogrammer. Du kan formatere teksten ved at gøre den fed, indsætte links, oprette lister, osv. Det unikke her er muligheden for at indsætte flettefelter. Fx, hvis du skriver {elevnavn}, vil Skoleadmin automatisk ved afsendelse erstatte teksten med elevens navn.</p>
                        <p><strong>TIP</strong>: Hvis du kopierer tekst fra andre programmer, kan der følge uønsket formatering med. Hvis dette sker, skal du blot markere teksten og trykke på knappen "<u>T</u>x" for at fjerne eventuelle formateringer.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Elev Stamdata">
                        <p>Med "Elev Stamdata" byggeklodsen kan du præsentere modtager for den nuværende stamdata, du har på eleven. Her har modtager mulighed for at opdatere den, hvis der er sket ændringer.</p>
                        <p>Du kan selv styre, hvilke stamfelter der vises, og hvilke der skal udfyldes.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Input Felt(er)">
                        <p>Med "Input Felt" byggeklodsen kan du indsætte inputfelter til modtager. Du kan vælge forskellige typer af input, såsom tekst, tal, e-mail eller telefonnummer, og angive om feltet skal være obligatorisk.</p>
                        <p><strong>TIP:</strong> Du kan tilføje flere inputfelter i samme byggeklods.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Samtykke(r)">
                        <p>Med "Samtykke" byggeklodsen kan du indsætte samtykker. Her kan du oprette et nyt samtykke eller vælge et eksisterende. Et samtykke er altid obligatorisk at svare på.</p>
                        <p><strong>TIP:</strong> Under menupunktet "Samtykker" kan du hurtigt og nemt få et overblik over de indhentede svar og filtrere samtykker baseret på både svar og årgange.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Spørgsmål">
                        <p>Med "Spørgsmål" byggeklodsen kan du indsætte et spørgsmål. Et spørgsmål består af et valgt antal svarmuligheder. Du kan selv definere, om spørgsmålet er obligatorisk, og om der kan gives flere svar.</p>
                        <p><strong>TIP:</strong> Undgå at bruge "Spørgsmål" byggeklodsen til samtykker. Brug i stedet "Samtykke" byggeklodsen for at få automatisk statistik.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Modtag dokumentation">
                        <p>Med "Modtag dokumentation" byggeklodsen har du mulighed for at lade modtagere uploade PDF-filer samt billeder. Dette kan være nyttigt, hvis din skole fx skal ud at rejse og har brug for en kopi af pas, har brug for en udenlandsk forældres årsopgørelse og lignende dokumenter.</p>
                        <p>Du kan vælge det maksimale antal filer, en modtager kan uploade, om upload er obligatorisk, og hvilken type indhold der uploades.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Video">
                        <p>Med "Video" byggeklodsen har du mulighed for at gøre dokumentet mere levende. Det kunne være en videopræsentation af skolen, en forklarende video og lignende.</p>
                    </AccordionTab>
                    <AccordionTab header="Byggeklods: Underskrift">
                        <p>Med "Underskrift" byggeklodsen har du mulighed for at indhente digitale underskrifter. Der er en række indstillinger, du skal tage stilling til.</p>
                        <p><strong>Hvem skal underskrive?</strong><br />Mange skoler vælger for eksempel at alle skal underskrive skolekontrakten for at sikre, at skolens retningslinjer er læst og forstået af eleven.</p>
                        <p><strong>Skal afsender også underskrive?</strong><br />Ønsker I som skole også at underskrive? Her kan I vælge at underskrive på forhånd eller efterfølgende. Jeres underskrift administreres under menupunktet "Indstillinger".</p>
                        <p><strong>Bekræft stamdata</strong><br />Når "Bekræft stamdata" er aktiveret, vil underskriveren efter endt underskrift blive præsenteret for sine nuværende stamdata, som kan opdateres.</p>
                        <p><strong>Hvordan skal der underskrives?</strong><br />Her kan du vælge imellem 3 forskellige metoder til underskrivning:<ol class="ml-4 mt-2"><li>MitID: Slutbrugeren underskriver ved hjælp af deres MitID-login.</li><li>Touch/mus: Slutbrugeren underskriver ved at tegne deres underskrift med fingeren/musen.</li><li>Knaptryk: Slutbrugeren underskriver ved at trykke på en knap.</li></ol></p>
                        <p>Hvis slutbrugeren er under 18 år, vil underskrivningen ske via touch/mus, selvom MitID er valgt på forhånd. Dette skyldes, at personer under 18 år ikke altid har et MitID og ikke er myndige. For alle tre underskriftsmetoder gemmer vi IP-adressen og datoen for underskriftstidspunktet for at kunne støtte bevisførelse ved eventuelle tvister.</p>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-6" id="contracts">Ansættelseskontrakter/bestyrelsesreferater</h4>
                <Accordion :activeIndex="hash === '#contracts' ? 0 : -1">
                    <AccordionTab header="Hvordan håndterer jeg ansættelseskontrakter/bestyrelsesreferater">
                        <div class="px-3">
                            <p>
                                Flere skoler er begyndt at bruger Skoleadmin til at underskrive og opbevare ansættelseskontrakter samt bestyrelsesreferater, hvilket er yderst positivt. Dog er der nogle forhold, som kræver opmærksomhed, indtil vi får implementeret en mere specialiseret løsning.
                            </p>
                            <p>
                                <strong>Oprettelse</strong><br />
                                Dokumenterne opretter I som I plejer. Husk at vælge at ALLE skal underskrive<br /><br />
                                <u>Ansættelseskontrakt</u>:<br />Opret ny "elev", som er den ansatte. Husk at udfylde CPR-nummer, så den ansatte ikke kommer i "børne" flowet<br /><br />
                                <u>Bestyrelsesreferat</u>:<br />Opret ny "elev", som er bestyrelsesformand. Husk at udfylde CPR-nummer, så den bestyrelsesformanden ikke kommer i "børne" flowet.<br />Tilføj "relationer" som er bestyrelsesmedlemmer<br /><br />

                            </p>
                            <p>
                                <strong>Afsendelse</strong><br />
                                Man udsender som normalt, hvor man vælger et dokument og en "elev", der repræsenterer den pågældende medarbejder eller bestyrelsesformand. Husk at sende til ALLE.
                            </p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-6">Import af kontakter (elever)</h4>
                <Accordion>
                    <AccordionTab header="Hvordan eksporterer man elever og relationer fra KOMiT?">
                        <div class="px-3">
                            <ol>
                                <li>Går ind på det skoleår, du ønsker eksport fra</li>
                                <li>Klik på "Udtræk, søg og ret"</li>
                                <li>Klik på "Udskrifter, forsendelser og ret data"</li>
                                <li>Klik på "Multimail/Brev/Eksport/Ret data"</li>
                                <li>
                                    Under ”Søgning Relationer” sættes der flueben i:
                                    Mor, Far, Samlever og Øvrige kontakter
                                </li>
                                <li>Vælg de elever der ønskes udtræk for</li>
                                <li>
                                    Klik på "Brev/Eksport"
                                </li>
                                <li>Klik på "Vælg alle felter"</li>
                                <li>Klik på "Vælg Eksport til…" og gem filen på din computer</li>
                            </ol>
                        </div>
                        <p class="flex">
                            <i class="pi pi-thumbs-up mt-2 mr-3 opacity-40" style="font-size:26px"></i>
                            <span>Vær opmærksom på at hvis man åbner den eksporterede csv-fil i et andet program, kan programmet finde på at konvertere CPR numrene til tal, hvilket betyder at nullet vil blive fjernet i de CPR-numre, der starter med 0. Ved import i Skoleadmin tjekker vi blandt andet for dette og advarer dig herom.</span>
                        </p>
                    </AccordionTab>
                    <AccordionTab header="Hvordan sammenligner Skoleadmin elever ved KOMiT import af eksisterende elever?">
                        <p>
                            Ved import tjekker vi følgende og i følgende rækkefølge:
                        </p>
                        <ol class="ml-4">
                            <li>Om der er et match på kartotek og elev nr</li>
                            <li>Hvis CPR-nummer er gyldigt, om der er et match på CPR-nummer</li>
                            <li>Hvis e-mail er gyldig, om der er et match på e-mail adressen</li>
                        </ol>
                    </AccordionTab>
                    <AccordionTab header="Import af elever fra KOMiT fejler">
                        <div>
                            Hvis du ved import får fejlbeskeden:
                            <Message severity="error" :closable="false">Der skete en fejl. Følgende felter mangler: ...</Message>
                            skyldes det som ofte en af følgende ting:
                            <div class="px-5 py-2">
                                <ol>
                                    <li>Du mangler at inkludere de nævnte felter i eksporten fra KOMiT</li>
                                    <li>Eksport csv-filen fra KOMiT bruger ',' (komma), som seperator, hvilket KOMiT's data ikke kan håndtere. Prøv i stedet at vælge "åbn med gåseøjne" i KOMiT</li>
                                </ol>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Hvorfor kommer alle forældre/relationer ikke med over ved import?">
                        <div>
                            For at en relation bliver oprettet i Skoleadmin kræver det følgende:
                            <div class="px-5 py-2">
                                <ol>
                                    <li>Relation har enten en mail-adresse eller CPR-nummer udfyldt</li>
                                    <li>Relation har forældremyndighed</li>
                                </ol>
                            </div>
                        </div>

                    </AccordionTab>
                    <AccordionTab header="Hvilke felter understøtter Skoleadmin-importformatet?">
                        <div>
                            Følgende felter kan importeres via Skoleadmin-formatet:
                            <div class="px-5 pt-3">
                                <ul>
                                    <li><strong>ExternalId</strong> (skal være unik)<br /> <span class="font-italic text-sm">Hvis du fx bruger "1" for to forskellige kontakter fra to grupper, vil den senest importerede overskrive den første. Sørg derfor for at bruge unikke værdier, som fx "2024-1", "2024-2", "2025-1" etc.</span></li>
                                    <li>ExternalGroupId</li>
                                    <li>CPR</li>
                                    <li>Navn</li>
                                    <li>Far_Navn</li>
                                    <li>Klasse</li>
                                    <li>Køn</li>
                                    <li>Adresse</li>
                                    <li>Postnummer</li>
                                    <li>By</li>
                                    <li>Kommune</li>
                                    <li class="mt-4">Far_CPR</li>
                                    <li>Far_Navn</li>
                                    <li>Far_Email (Påkrævet)</li>
                                    <li>Far_Mobil</li>
                                    <li>Far_Adresse</li>
                                    <li>Far_Postnummer</li>
                                    <li>Far_By</li>
                                    <li class="mt-4">Mor_CPR</li>
                                    <li>Mor_Navn</li>
                                    <li>Mor_Email (Påkrævet)</li>
                                    <li>Mor_Mobil</li>
                                    <li>Mor_Adresse</li>
                                    <li>Mor_Postnummer</li>
                                    <li>Mor_By</li>
                                </ul>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-6" id="retention-rules">Sletteregler (Papirkurv/arkiv)</h4>
                <Accordion :activeIndex="hash === '#retention-rules' ? 0 : -1">
                    <AccordionTab header="Sletteregler">
                        <div class="px-3">
                            <p>Slettereglerne styres under indstillinger og på en gruppe.</p>
                            <p>
                                <strong>Gruppe</strong><br />
                                På en gruppe kan man indstille en slutdato. Når slutdatoen er passeret, flyttes gruppen til papirkurven.
                            </p>
                            <p>
                                <strong>Indstillinger > Sletteregler > Kontakter</strong><br />
                                Her kan du indstille, hvor mange dage der skal gå, før en kontakt uden gruppe flyttes til papirkurven. En kontakt betragtes som uden gruppe, hvis gruppen slettes og kontakten ikke er medlem af andre grupper.
                            </p>
                            <p>
                                <strong>Indstillinger > Sletteregler > Papirkurv</strong><br />
                                Her kan du indstille, hvor mange dage et slettet dokument, samtykke, skabelon, kontakt eller gruppe skal opbevares i papirkurven, før den slettes permanent. Dokumenter og samtykker overføres til arkivet.
                            </p>
                            <p>
                                <strong>Indstillinger > Sletteregler > Arkiv</strong><br />
                                Her kan du indstille, hvor mange måneder et dokument eller samtykke skal opbevares i arkivet.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Papirkurv">
                        <div class="px-3">
                            <p>Papirkurven fungerer som et mellemliggende trin inden permanent sletning eller arkivering. Den tilbyder en ekstra sikkerhed, så du kan gendanne et dokument, samtykke, skabelon, kontakt eller gruppe, hvis det bliver slettet ved en fejl.<br />Hvor lang tid det ligger i papirkurven styres under indstillinger > sletteregler.</p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Arkiv">
                        <div class="px-3">
                            <p>Arkivet er sidste trin inden permanent sletning og det sted, hvor du kan fremsøge dokumenter og samtykker ved hjælp af CPR-nummer eller fuldt navn, hvis du får brug for dem til dokumentation. Hvor længe et dokument eller samtykke opbevares i arkivet, styres under Indstillinger > Sletteregler.</p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h4 class="mt-6">Statsstøtteskemaet</h4>
                <Accordion>
                    <AccordionTab header="Bliver statsstøtteskemaet sendt til alle relationer?">
                        <p>
                            Nej, ikke nødvendigvis. Hvis en elev har adresse udfyldt og en relation har samme adresse vil Skoleadmin kun sende til dem der matcher. Hvis elev ikke har adresse udfyldt eller ingen af relationerne matcher vil den sende til alle.<br />
                            Inde på elev detaljer, kan man se hvem der vil blive sendt til ved at kigge på om der er flueben under "MODTAGER STATSSTØTTESKEMAET".
                        </p>
                    </AccordionTab>
                    <AccordionTab header="Hvordan ser jeg indsendt data?">
                        <p>
                            Hvis man klikker sig ind på "Statistik for Statsstøtteskemaet" kan man ud for hver elev klikke på øjet for at se det udfyldte skema. CPR nummeret vil af sikkerhedsmæssige årsager være obfuskeret fx 0101XX-XXXX.
                        </p>
                        <p>
                            Hvis man derimod skal til at søge elevstøtte eller ønsker at se CPR-numre, kan man på statistiksiden klikke på den grønne knap:<br />
                            <Button icon="pi pi-sync" label="Se indsendt data og eventuelle forskelle" class="p-button-success mt-2 mb-3" />
                            <br />hvor man får en hurtig oversigt over alle elever og relevant data samt eventuelle forskelle i den data, I havde på forhånd. Dette indeholder alt, hvad man skal bruge til at søge elevstøtte. Ved at trykke på ">" til venstre for elevnavnet kan man se, hvilke forskelle der er, samt CPR-numrene.
                            <br /><br />Hvis man søger via KOMiT, kan man klikke på det grønne flueben til højre for hver elev, når man har indtastet i KOMiT.<br />
                            <Button v-tooltip.top="'Data indtastet i KOMiT'" class="p-button-success p-button-icon-only mt-2 mb-3">
                                <span class="pi pi-check p-button-icon"></span>
                                <span class="p-button-label">&nbsp;</span>
                            </Button>
                            <br />Herefter vil eleven ryge ned i en anden liste, så man kan skelne mellem elever man har tastet/søgt for og elever man mangler at gennemgå.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="CPR-nummeret ved statsstøtteskemaet slutter på XX-XXXX, hvordan ser jeg hele CPR-nummeret?">
                        <p>
                            Ved preview af statsstøtteskemaet har vi skjult de sidste 6 cifre af CPR-numrene af sikkerhedsmæssige årsager. Hvis man har brug for at se dem, læs ovenstående punkt "Hvordan ser jeg indsendt data"
                        </p>
                    </AccordionTab>
                    <AccordionTab header="Hvordan håndterer vi udenlandske elever?">
                        <p>Hvis den kommende elev og forældre bor i udlandet, men har dansk CPR-nummer, kan de godt udfylde skemaet. Ved adresse vælger de blot "Kan du ikke finde din adresse?" og udfylder adressen manuelt.</p>
                        <p>Hvis de ikke har dansk CPR-nummer, skal elevstøtte søges på en anden manuel måde.</p>
                    </AccordionTab>
                    <AccordionTab header="En forælder ringer og fortæller, at de har opdaget en fejl i statsstøtteskemaet. Hvad gør man?">
                        <p>
                            Hvis de er begyndt at underskrive dokumentet, er dokumentet låst og kan ikke ændres mere. Her vil man skulle sende et nyt statsstøtteskema og slette det gamle.
                        </p>
                        <p>
                            Hvis de ikke er begyndt at underskrive, kan de klikke på linket fra mailen og rette i skemaet. Hvis de ikke har mailen mere, kan I sende en påmindelse, så de får et nyt link.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="Jeg har kun sendt til forældre/relationer, hvorfor skal eleven også underskrive?">
                        <p>
                            Ved statsstøtteskemaet skal eleven også underskrive. Det er ikke noget vi kan fjerne, da det er en del af det officielle dokument. Eleven kan underskrive via en af de andre der underskriver. Hvis det kun er eleven, der mangler at underskrive og ikke har udfyldt en e-mail adresse, kan man sende en påmindelse til forældrene ved at klikke på send påmindelse og sætte hak i "send til alle".
                        </p>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useRoute } from "vue-router"

    const route = useRoute();
    const { hash } = route;

    onMounted(() => {
        console.log(hash);
        if (hash) {
            var ele = document.querySelector(hash);
            if (ele) {
                setTimeout(function () {
                    ele.scrollIntoView({ behavior: 'smooth' });
                }, 100);
               
            }
        }
    })
</script>

<style>
    .p-accordion-header{
        background-color:white;
    }

    .page-admin-help h4 {
        scroll-margin-top: 100px;
    }
</style>
