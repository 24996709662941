<!-- TODO: Attempt to render dynamic html form API. works only once -->
<script>
    import { h, inject, reactive } from 'vue';
    //import api from '../composables/api';

    export default {
        props: {
            markup: {
                required: true,
                type: String
            },
            token: {
                required: true,
                type: String
            }
        },

        render(props) {
            const store = inject('store');
            const apiUrl = inject('apiUrl');
            const vm = reactive({
                busy: false,
                saved: false,
                submitting: false,
                html: props.markup,
                token: props.token
            });


            console.log('customerId', store.auth.customerId, apiUrl);
            const render = {
                template: "<div>" + vm.html + "</div>",
                //template: "#template",
                data: () => ({
                    html: props.markup
                }),
                methods: {
                    approve() {
                        console.log('click comp');
                    },
                    readyToSign(event) {
                        this.validateForm(event, document.querySelector('form'), this.saveAndGoToReadyToSign);
                    },
                },
            }
            return h(render)
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
