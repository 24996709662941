<template>
    <div class="page-document">
        <div v-if="vm.busy" style="margin: 100px auto 0" class="loader-wrapper">
            <ProgressSpinner />
        </div>
        <div v-else style="width:100%">
            <div v-if="vm.view === 'document'">
                <div v-if="vm.error" class="document">
                    <div class="text-center" style="width:100%">
                        <h2>Øv. Der skete en fejl!</h2>
                        <pre>{{ vm.error }}</pre>
                    </div>
                </div>
                <div v-else>
                    <component :is="vm.dynamicHtml"></component>
                </div>
            </div>
            <div v-if="vm.view === 'student-grant-form'">
                <student-grant-form></student-grant-form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineAsyncComponent, inject, reactive, shallowRef } from 'vue';
    import api from '../composables/api';
    import gqlBuilder from '../composables/gql-builder';
    import { useRoute } from 'vue-router';

    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const route = useRoute();

    const vm = reactive({
        busy: false,
        error: false,
        view: null,
        documentId: null,
        memberId: null,
        dynamicHtml: shallowRef(null)
    });

    const getDocumentById = (id) => {
        vm.busy = true;
        api.get(apiUrl + 'customer/' + store.auth.customerId + '/document/' + id)
            .then((response) => {
                if (response) {
                    vm.view = 'document';
                    vm.dynamicHtml = getAsyncComponent(response);
                }
                vm.busy = false;
            })
            .catch((error) => {
                vm.busy = false;
                vm.error = error;
            });
    };

    const getDocumentByTemplateId = (id) => {
        vm.busy = true;
        api.query(gqlBuilder.documentTemplates.preview(store.auth.customerId, id))
            .then((response) => {
                if (api.hasData(response)) {
                    var document = api.getData(response);
                    vm.view = 'document';
                    vm.dynamicHtml = getAsyncComponent(document.renderPreviewOutput);
                } else {
                    vm.error = '...';
                    vm.view = 'document';
                }
                vm.busy = false;
            });
    };

    const init = () => {
        vm.documentTemplateId = route.params.dtid;
        vm.documentId = route.params.did;

        if (vm.documentId) {
            getDocumentById(vm.documentId);
        } else if (vm.documentTemplateId) {
            getDocumentByTemplateId(vm.documentTemplateId);
        }
    };

    init();

    // Private function
    function getAsyncComponent(markup) {
        return defineAsyncComponent(() => {
            return new Promise((resolve) => {
                resolve({
                    template: markup,
                });
            });
        });
    }
</script>
