<template>    
    <div class="component-confirm-member-data my-5 pt-4">
        <div v-if="vm.data">
            <form novalidate autocomplete="off">
                <input id="md_mrd_id" type="hidden" :value="vm.data.id" />
                <div class="grid">
                    <div class="col-12" :class="{ 'md:col-5': vm.lang==='da_en', 'md:col-4': vm.lang!=='da_en'}">
                        <span class="p-float-label">
                            <InputMask id="md_mrd_personalId" type="text" class="p-100 p-filled" v-model="v$.data.personalIdEncrypted.$model" mask="999999-9999" placeholder="xxxxxx-xxxx" slotChar="x" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.personalIdEncrypted.$dirty) && v$.data.personalIdEncrypted.$invalid}" required />
                            <label v-if="vm.lang==='en'" for="md_mrd_personalId">National ID</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_personalId">CPR-nummer / National ID</label>
                            <label v-else for="md_mrd_personalId">CPR-nummer</label>
                        </span>
                        <small v-if="(vm.validateForm || v$.data.personalIdEncrypted.$dirty) && v$.data.personalIdEncrypted.$invalid" class="p-error">CPR-nummer skal udfyldes</small>
                    </div>
                    <div class="col-12" :class="{ 'md:col-7': vm.lang==='da_en', 'md:col-8': vm.lang!=='da_en'}">
                        <span class="p-float-label">
                            <InputText id="md_mrd_name" type="text" class="p-100" v-model="v$.data.name.$model" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.name.$dirty) && v$.data.name.$invalid}" required />
                            <label v-if="vm.lang==='en'" for="md_mrd_name">Name</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_name">Fulde navn / Name</label>
                            <label v-else for="md_mrd_name">Fulde navn</label>
                        </span>
                        <small v-if="(vm.validateForm || v$.data.name.$dirty) && v$.data.name.$invalid" class="p-error">Navn skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="md_mrd_address" type="text" class="p-100" v-model="v$.data.address.$model" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.address.$dirty) && v$.data.address.$invalid}" required />
                            <label v-if="vm.lang==='en'" for="md_mrd_address">Address</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_address">Adresse / Address</label>
                            <label v-else for="md_mrd_address">Adresse</label>
                        </span>
                        <small v-if="(vm.validateForm || v$.data.address.$dirty) && v$.data.address.$invalid" class="p-error">Adresse skal udfyldes</small>
                    </div>
                                        
                    <div class="col-5 sm:col-4" :class="{ 'lg:col-4': vm.lang==='da_en', 'lg:col-3': vm.lang!=='da_en'}">
                        <span class="p-float-label">
                            <InputText id="md_mrd_zip" type="number" class="p-100" v-model="v$.data.zip.$model" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.zip.$dirty) && v$.data.zip.$invalid}" required />
                            <label v-if="vm.lang==='en'" for="md_mrd_zip">Postal code</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_zip">Postnr / Postal code</label>
                            <label v-else for="md_mrd_zip">Postnr</label>
                        </span>
                        <small v-if="(vm.validateForm || v$.data.zip.$dirty) && v$.data.zip.$invalid" class="p-error">Postnr skal udfyldes</small>
                    </div>
                    <div class="col-7 sm:col-8" :class="{ 'lg:col-8': vm.lang==='da_en', 'lg:col-9': vm.lang!=='da_en'}">
                        <span class="p-float-label">
                            <InputText id="md_mrd_city" type="text" class="p-100" v-model="v$.data.city.$model" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.city.$dirty) && v$.data.city.$invalid}" required />
                            <label v-if="vm.lang==='en'" for="md_mrd_city">City</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_city">By / City</label>
                            <label v-else for="md_mrd_city">By</label>
                        </span>
                        <small v-if="(vm.validateForm || v$.data.city.$dirty) && v$.data.city.$invalid" class="p-error">By skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="md_mrd_email" type="email" class="p-100" v-model="v$.data.email.$model" autocomplete="off" role="presentation" :class="{'p-invalid':(vm.validateForm || v$.data.email.$dirty) && v$.data.email.$invalid}" required />
                            <label for="md_mrd_email">E-mail</label>                            
                        </span>
                        <small v-if="(vm.validateForm || v$.data.email.$dirty) && v$.data.email.email.$invalid" class="p-error">Den indtastede e-mail er ikke gyldig</small>
                        <small v-else-if="(vm.validateForm || v$.data.email.$dirty) && v$.data.email.$invalid" class="p-error">E-mail skal udfyldes</small>
                    </div>
                    <div class="col-12">
                        <span class="p-float-label">
                            <InputText id="md_mrd_phone" type="text" class="p-100" v-model="vm.data.phone" autocomplete="off" role="presentation" />
                            <label v-if="vm.lang==='en'" for="md_mrd_phone">Mobile number</label>
                            <label v-else-if="vm.lang==='da_en'" for="md_mrd_phone">Mobilnummer / Mobile number</label>
                            <label v-else for="md_mrd_phone">Mobilnummer</label>
                        </span>
                    </div>
                    <!--<div class="col-12 flex align-items-center">
                        <InputSwitch inputId="md_mrd_legalGuardian" v-model="vm.data.legalGuardian" />
                        <label for="md_mrd_legalGuardian" class="ml-2"> Har forældremyndighed?</label>
                    </div>-->
                </div>
                <div class="mt-5 mb-3 text-right">
                    <Button v-if="vm.lang==='en'" label="Confirm" @click="fn.submit(v$)" />
                    <Button v-else-if="vm.lang==='da_en'" label="Bekræft / Confirm" @click="fn.submit(v$)" />
                    <Button v-else label="Bekræft" @click="fn.submit(v$)" />
                </div>
            </form>
        </div>
    </div>
</template>


<script>
    import { defineComponent, reactive, computed } from 'vue';
    import { required, email } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";

    export default defineComponent({
        name: 'ConfirmMemberData',
        props: {
            data: Object,
            lang: String
        },
        emits: ['confirm'],
        setup(props, { emit }) {
            const vm = reactive({
                data: props.data,
                validateForm: false,
                lang: props.lang
            });
            const validationRules = computed(() => {
                return {
                    data: {
                        personalIdEncrypted: { required },
                        name: { required },
                        address: { required },
                        zip: { required },
                        city: { required },
                        email: { required, email },
                    }
                }
            });

            let v$ = useVuelidate(validationRules, vm);

            const fn = {
                submit: (form) => {
                    vm.validateForm = true;
                    if (form.$invalid) {
                        //Scroll to first error
                        setTimeout(() => {
                            var errors = document.getElementsByClassName('p-invalid');
                            if (errors && errors.length) {
                                errors[0].scrollIntoView();
                            }  
                        }, 100);

                        return;
                    }
                    emit('confirm', vm.data);
                }
            };

            return { vm, fn, v$ };
        }
    });
</script>
<style scoped lang="scss">
    .p-button-remove-guardian {
        position: absolute;
        right: 0px;
        top: -15px;
    }

    @media (min-width: 480px) {
        .component-confirm-member-data {
            input[required] + label:after {
                content: '(påkrævet)';
                margin: 0 0.25rem;
            }
        }
    }

    .p-inputswitch + label {
        text-align: left;
        word-wrap: break-word;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }

    .p-error{
        display:inline-block;
        width:100%;
        text-align:left;
    }
</style>
