<template>
    <div class="page-admin-settings">
        <page-header headline="Indstillinger">
            <Button label="Gem" class="p-button-success" @click="fn.save" />
        </page-header>
        <div class="content-wrapper">
            <div class="main-content">
                <div v-if="vm.customerInfo && !vm.fetchingData">
                    
                    <div class="grid nested-grid">
                        <div class="col-12 xl:col-8">
                            <div class="box">
                                <div class="grid">
                                    <div class="col-12">
                                        <h4 class="mb-3">Stamdata</h4>
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.vatNo" readonly disabled />
                                            <label for="inputname">CVR Nummer</label>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.name" />
                                            <label for="inputname">Navn</label>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.street" />
                                            <label for="inputname">Vejnavn</label>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.zip" />
                                            <label for="inputname">Postnummer</label>
                                        </span>
                                    </div>
                                    <div class="col-8">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.city" />
                                            <label for="inputname">By</label>
                                        </span>
                                    </div>
                                    <div class="col-8">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.email" />
                                            <label for="inputname">E-mail</label>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="p-float-label mb-3">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.phone" />
                                            <label for="inputname">Telefon</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 xl:col-4">
                            <div class="box h-full">
                                <h4>Logo</h4>
                                <div class="box flex flex-column justify-content-between justify-content-around text-center" style="height: 260px; background: #f3f4ff;">
                                    <div v-if="vm.customerInfo && vm.customerInfo.logoUrl">
                                        <Image :src="vm.customerInfo.logoUrl" alt="Image" preview imageStyle="max-height: 170px;" />
                                        <div class="flex justify-content-center">
                                            <FileUpload class="mt-3" mode="basic" name="upload[]" accept="image/*" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onImageUpload($event)" :auto="true" chooseLabel="Skift logo" />
                                            <Button label="Slet" icon="pi pi-trash" class="p-button-danger mt-3 ml-2" @click="fn.removeImage"></Button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <FileUpload mode="basic" name="upload[]" accept="image/*" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onImageUpload($event)" :auto="true" chooseLabel="Upload logo" />
                                    </div>
                                </div>
                                <div class="mt-3 font-italic">
                                    <small>Logoet anvendes i forbindelse med visning af et dokument. Det vil blive placeret i øverste højre hjørne og vil maksimalt være 150 pixels bredt.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid mt-2">
                        <div class="col-12 xl:col-4">
                            <div class="box">
                                <h4>Signatur</h4>
                                <div class="box flex flex-column justify-content-between justify-content-around text-center" style="height: 260px; background: #f3f4ff">
                                    <div v-if="vm.customerInfo && vm.customerInfo.signatureUrl">
                                        <Image :src="vm.customerInfo.signatureUrl" alt="Image" preview imageStyle="max-height: 170px;" />
                                        <div class="flex justify-content-center">
                                            <FileUpload class="mt-3" mode="basic" name="upload[]" accept="image/*" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onSignatureUpload($event)" :auto="true" chooseLabel="Skift signatur" />
                                            <Button label="Slet" icon="pi pi-trash" class="p-button-danger mt-3 ml-2" @click="fn.removeSignature"></Button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <FileUpload mode="basic" name="upload[]" accept="image/*" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onSignatureUpload($event)" :auto="true" chooseLabel="Upload signatur" />
                                    </div>
                                </div>
                                <span class="p-float-label mt-3">
                                    <InputText id="signaturename" type="text" class="p-100 p-inputtext-lg" v-model="vm.customerInfo.signatureName" />
                                    <label for="signaturename">Signatur navn</label>
                                </span>
                                <div class="mt-3 font-italic">
                                    <small>Signaturen bruges, hvis der i underskriftsmodulet i skabelonen er valgt at afsender skal underskrive. For bedste resultat anbefales det at uploade et billede med hvid baggrund i størrelsen 400x200 pixels.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 xl:col-4">
                            <div class="box h-full">
                                <div class="grid">
                                    <div class="col-12">
                                        <h4 class="mb-3">Sletteregler</h4>
                                        <span class="p-float-label">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg pr-7" v-model="vm.customerInfo.retentionPolicy.memberRetentionPeriod" />
                                            <label for="inputname">Kontakter</label>
                                            <span class="absolute align-items-center bottom-0 flex pr-3 right-0 top-0">dag(e)</span>
                                        </span>
                                        <div class="mt-1 font-italic">
                                            <small>Antal dage der skal gå før en kontakt, som ikke findes i en gruppe, bliver overført til papirkurven.</small>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <span class="p-float-label">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg pr-7" v-model="vm.customerInfo.retentionPolicy.recycleBinPeriod" />
                                            <label for="inputname">Papirkurv</label>
                                            <span class="absolute align-items-center bottom-0 flex pr-3 right-0 top-0">dag(e)</span>
                                        </span>
                                        <div class="mt-1 font-italic">
                                            <small>Antal dage et dokument, samtykke, skabelon, kontakt eller gruppe er i papirkurven før det bliver slettet eller overført til arkivet.</small>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <span class="p-float-label">
                                            <InputText id="inputname" type="text" class="p-100 p-inputtext-lg pr-7" v-model="vm.customerInfo.retentionPolicy.archivePeriod" />
                                            <label for="inputname">Arkiv</label>
                                            <span class="absolute align-items-center bottom-0 flex pr-3 right-0 top-0">måned(er)</span>
                                        </span>
                                        <div class="mt-1 font-italic">
                                            <small>Antal måneder et dokument eller samtykke skal gemmes i arkivet før det bliver endeligt slettet.</small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 xl:col-4">
                            <div class="box h-full">
                                <div v-if="store.config && store.config.partnerName" class="mb-5">
                                    <h4>Aftaleforhold</h4>
                                    <small>Skoleadmin er købt gennem <strong>{{store.config.partnerName}}</strong>, hvorfor det er {{store.config.partnerName}}s vilkår og betingelser, der gælder, og {{store.config.partnerName}}, der yder support.</small>
                                </div>
                                <h4>Databehandleraftale</h4>
                                <small>Databehandleraftalen mellem jer og Skoleadmin fungerer som en instruktion i, hvordan vi er forpligtede til at behandle dine data jf. EU-lovgivningen om persondata (GDPR).</small>
                                <div class="text-center"><Button label="Hent databehandleraftale" icon="pi pi-download" class="p-button-success p-button-sm mt-6" @click="fn.getGdprAgreement()"></Button></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, reactive } from 'vue';
    import api from './../../composables/api';
    import { useToast } from 'primevue/usetoast';


    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const vm = reactive({
        customerInfo: { retentionPolicy: {} },
        apiHost: inject('apiHost'),
        fetchingData: false,
        configuration: null
    });
    const fn = {};

    fn.onImageUpload = (event) => {
        var formData = new FormData();
        formData.append('file', event.files[0]);

        api.postFormData(apiUrl + 'customers/' + store.auth.customerId + '/logo', formData).then((url) => {
            vm.customerInfo.logoUrl = url;
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Øv, billedet kan ikke uploades', detail: error });
        });
    };

    fn.removeImage = () => {
        api.del(apiUrl + 'customers/' + store.auth.customerId + '/logo', {}).then(() => {
            vm.customerInfo.logoUrl = null;
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Øv, billedet kan ikke fjernes på nuværende tidspunkt', detail: error });
        });
    };

    fn.onSignatureUpload = (event) => {
        var formData = new FormData();
        formData.append('file', event.files[0]);

        api.postFormData(apiUrl + 'customers/' + store.auth.customerId + '/signature', formData).then((url) => {            
            vm.customerInfo.signatureUrl = url;
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Øv, billedet kan ikke uploades', detail: error });
        });
    };

    fn.removeSignature = () => {
        api.del(apiUrl + 'customers/' + store.auth.customerId + '/signature', {}).then(() => {
            vm.customerInfo.signatureUrl = null;
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Øv, billedet kan ikke fjernes på nuværende tidspunkt', detail: error });
        });
    };

    fn.save = () => {
        api.patch(apiUrl + 'customers/' + store.auth.customerId, vm.customerInfo).then(() => {
            toast.add({ severity: 'success', summary: 'Gennemført', detail: 'Indstillinger gemt', life: 4000 });
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });        
        });        
    };

    fn.getGdprAgreement = () => {
        window.open(apiUrl + 'admin/gdpragreement/' + store.auth.customerId, '_blank');
    }

    // INIT
    vm.fetchingData = true;

    api.get(apiUrl + 'customers/' + store.auth.customerId).then((customer) => {
        vm.customerInfo = customer;
        vm.fetchingData = false;
    }).catch((error) => {
        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        vm.fetchingData = false;
    });


</script>

<style>
    .page-admin-settings .p-image img {
        max-width: 100%;
    }
</style>