<template>
    <div class="page-admin-integrations">
        <page-header headline="Integrationer">
            <Button label="Opret ny integration" icon="pi pi-plus" class="p-button-success" @click="fn.showCreateSidebar" />
        </page-header>
        <div class="content-wrapper">
            <div class="main-content" v-if="vm.initialized">
                <div v-if="vm.items.length > 0">
                    <DataTable stripedRows
                               :value="vm.items"
                               dataKey="id"
                               sortField="updated" :sortOrder="-1"
                               :filters="filters"
                               responsiveLayout="scroll">
                        <Column field="name" header="Navn" sortable>
                            <template #body="slotProps">
                                <div class="flex p-link clickable" @click="fn.showEditSidebar(slotProps.data)">
                                    <strong>{{ slotProps.data.name }}</strong>
                                </div>
                            </template>
                        </Column>
                        <Column field="clientId" header="Client ID"></Column>
                        <Column field="updated" header="Oprettet" sortable style="width:230px">
                            <template #body="slotProps">
                                {{ $filters.formatDate(slotProps.data.created) }}
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggle($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="itemExtraActions" :popup="false" :class="{'block': vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <Message severity="info" :closable="false" v-else>
                    Der er ingen integrationer oprettet
                </Message>
            </div>
        </div>

        <Sidebar v-model:visible="vm.slideInCreate" :baseZIndex="1000" position="right" class="p-sidebar-md">
            <div class="flex flex-column h-full">
                <h3>{{ vm.currentIntegration.id ? 'Rediger integration' : 'Opret ny integration' }}</h3>
                <div class="box mt-2">
                    <span class="p-float-label">
                        <InputText id="inputname" type="text" class="w-full p-inputtext-lg" v-model="vm.currentIntegration.name" />
                        <label for="inputname">Indtast navn på integration *</label>
                    </span>
                    <div v-if="vm.currentIntegration.clientId" class="flex mt-3">
                        <span class="p-float-label w-full">
                            <InputText id="inputid" type="text" class="w-full p-inputtext-lg" v-model="vm.currentIntegration.clientId" disabled />
                            <label for="inputid">Client ID</label>
                        </span>
                        <Button type="button" severity="success" icon="pi pi-copy" label="Kopiér" class="ml-1" @click="fn.copyToClipboard(vm.currentIntegration.clientId)" />
                    </div>
                    
                </div>
                <div v-if="vm.currentIntegration.secrets && vm.currentIntegration.secrets.length">
                    <DataTable stripedRows
                               :value="vm.currentIntegration.secrets"
                               dataKey="name"
                               :sortOrder="-1"
                               responsiveLayout="scroll">
                        <Column field="info" header="Client secret">
                            <template #body="slotProps">

                                <strong>
                                    {{ slotProps.data.info.slice(0, 3) }}xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx...
                                </strong>

                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text mr-2 py-0 h-2rem w-2rem" @click="fn.deleteClientSecret(slotProps.data.name)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="mt-3" v-if="vm.currentIntegration.id">
                    <Button label="Opret client secret" class="p-button p-button-sm" @click="fn.createClientSecret" />
                </div>
                <div class="mt-auto align-self-end sticky bottom-spacing">
                    <Button :label="vm.currentIntegration.id ? 'Gem' : 'Opret'" class="p-button-success p-button-lg" @click="fn.createOrUpdateIntegration" :disabled="!vm.currentIntegration.name" />
                </div>
            </div>
        </Sidebar>

        <ConfirmDialog group="confirmDelete" :style="{ maxWidth: '600px', width: '100%' }">
            <div>Er du sikker på at du vil slette <b>{{ vm.currentItem?.name }}</b>?</div>
            <Message severity="warn" :closable="false">
                Når en integration først er slettet, kan den ikke gendannes. Alle systemer, der forsøger at få adgang til systemet med denne integration, vil ikke længere kunne tilgå systemet.
            </Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteSecret" :style="{ maxWidth: '600px', width: '100%' }">
            <div>Er du sikker på, at du vil slette denne client secret?</div>
            <Message severity="warn" :closable="false">
                Når en client secret først er slettet, kan den ikke gendannes. Alle systemer, der forsøger at få adgang til systemet med denne client secret, vil ikke længere kunne tilgå systemet.
            </Message>
        </ConfirmDialog>


        <Dialog v-model:visible="vm.showSecret" modal header="Din client secret er nu oprettet!" :style="{ maxWidth: '51rem', width: '100%' }">
            <div class="flex my-2">
                <span class="p-float-label flex-grow-1">
                    <InputText id="client-secret" type="text" class="p-100 p-inputtext-lg" v-model="vm.currentIntegration.clientId" disabled />
                    <label for="client-secret">Client ID</label>
                </span>
                <Button type="button" severity="success" icon="pi pi-copy" label="Kopiér" class="ml-1" @click="fn.copyToClipboard(vm.currentIntegration.clientId)" />
            </div>
            <div class="flex my-3">
                <span class="p-float-label flex-grow-1">
                    <InputText id="client-secret" type="text" class="p-100 p-inputtext-lg" v-model="vm.clientSecret" disabled />
                    <label for="client-secret">Client secret</label>
                </span>
                <Button type="button" severity="success" icon="pi pi-copy" label="Kopiér" class="ml-1" @click="fn.copyToClipboard(vm.clientSecret)" />
            </div>
            <Message severity="warn" :closable="false">
                <strong>VIGTIGT! Kopiér din client secret nu, da den kun vises én gang. Du vil ikke kunne se den igen senere.</strong>
            </Message>
            <Message severity="info" :closable="false">
                <strong>Hvad er en client secret?</strong><br />
                En client secret er en hemmelig nøgle, der fungerer som en adgangskode, der sikrer, at kun autoriserede applikationer kan få adgang til dine data i systemet. Det er vigtigt at holde den hemmelig for at beskytte dine data.
            </Message>
        </Dialog>

        <div v-if="vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, reactive } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import api from './../../composables/api';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from 'primevue/useconfirm';

    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const confirm = useConfirm();

    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const vm = reactive({
        items: [],
        busy: false,
        initialized: false,
        showSecret: false,
        clientSecret: null,
        slideInCreate: false,
        currentIntegration: {},
        visibleMenuId: null,
        currentItem: null
    });

    const itemExtraActions = ref([
        {
            label: 'Se detaljer/rediger',
            icon: 'pi pi-pencil',
            command: () => {
                fn.showEditSidebar(vm.currentItem);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                confirm.require({
                    group: 'confirmDelete',
                    header: 'Bekræft slet',
                    acceptLabel: 'Ja, slet',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, fortryd',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => fn.delete(vm.currentItem)
                });
                vm.visibleMenuId = null;
            }
        }
    ]);

    const fn = {
        showCreateSidebar() {
            vm.currentItem = null;
            vm.clientSecret = null;
            vm.currentIntegration = {};
            vm.slideInCreate = true;
        },
        showEditSidebar(item) {
            vm.clientSecret = null;
            vm.currentItem = vm.currentIntegration = item;

            api.get(`${apiUrl}clientApplications/${item.id}/secrets`)
                .then(response => {
                    vm.currentIntegration.secrets = response;
                })
                .catch(error => handleError('Der skete en fejl', error));

            vm.slideInCreate = true;
        },
        createOrUpdateIntegration() {
            vm.busy = true;
            const endpoint = vm.currentIntegration.id ? `clientapplications/${vm.currentIntegration.id}` : 'clientapplications';
            const method = vm.currentIntegration.id ? 'patch' : 'postJson';
            api[method](apiUrl + endpoint, vm.currentIntegration)
                .then(response => {
                    if (!vm.currentIntegration.id && response) {
                        vm.currentIntegration = response;
                        vm.items.push(vm.currentIntegration);
                        fn.createClientSecret();
                    }
                    vm.slideInCreate = false;
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => vm.busy = false);
        },
        createClientSecret() {
            vm.clientSecret = generate256BitHex();

            api.postJson(`${apiUrl}clientapplications/${vm.currentIntegration.id}/secrets`, vm.clientSecret)
                .then(() => {
                    vm.currentIntegration.secrets?.push({ info: vm.clientSecret });
                    vm.showSecret = true;
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => vm.busy = false);
        },
        deleteClientSecret(name) {
            confirm.require({
                group: 'confirmDeleteSecret',
                header: 'Bekræft slet',
                acceptLabel: 'Ja, slet',
                acceptClass: 'p-button-success p-button-lg',
                rejectLabel: 'Nej, fortryd',
                rejectClass: 'p-button-link p-button-lg',
                accept: () => {
                    vm.busy = true;
                    api.del(`${apiUrl}clientapplications/${vm.currentIntegration.id}/secrets/${name}`)
                        .then(() => {
                            vm.currentIntegration.secrets = vm.currentIntegration.secrets.filter(obj => obj.name !== name);
                            toast.add({ severity: 'success', summary: 'Gennemført', detail: 'Client secret slettet', life: 5000 });
                        })
                        .catch(error => handleError('Der skete en fejl', error))
                        .finally(() => vm.busy = false);
                }
            });
        },
        delete(item) {
            vm.busy = true;
            api.del(`${apiUrl}clientapplications/${item.id}`)
                .then(() => {
                    vm.items = vm.items.filter(obj => obj.id !== item.id);
                    toast.add({ severity: 'success', summary: 'Gennemført', detail: `${item.name} slettet`, life: 5000 });
                })
                .catch(error => handleError('Der skete en fejl', error))
                .finally(() => vm.busy = false);
        },
        copyToClipboard(textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => toast.add({ severity: 'success', summary: 'Kopieret til udklipsholderen', life: 5000 }))
                .catch(err => console.error('Kunne ikke kopiere: ', err));
        },
        toggle(event, data) {
            vm.currentItem = data;
            vm.visibleMenuId = vm.currentItem.id === vm.visibleMenuId ? null : vm.currentItem.id;
        }
    };

    function handleError(summary, error) {
        toast.add({ severity: 'error', summary, detail: error });
    }

    function generate256BitHex() {
        const randomValues = crypto.getRandomValues(new Uint8Array(32));
        return Array.from(randomValues, byte => byte.toString(16).padStart(2, '0')).join('');
    }

    // Init data
    api.get(`${apiUrl}clientApplications`)
        .then(response => {
            vm.items = response || [];
            vm.initialized = true;
        })
        .catch(error => handleError('Der skete en fejl', error));
</script>
