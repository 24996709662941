<template>
    <div class="component-master-data my-5">
        <div v-if="vm.data.student">
            <div v-if="vm.readonly">
                <div class="grid">
                    <div class="col-12" v-if="vm.data.headline">
                        <h4 class="ml-3">{{vm.data.headline}}</h4>
                    </div>
                    <div class="col-12" v-else>
                        <h4 v-if="vm.lang === 'en'" class="ml-3">Basic student information</h4>
                        <h4 v-else-if="vm.lang === 'da_en'" class="ml-3">Stamdata for eleven / Basic student information</h4>
                        <h4 v-else class="ml-3">Stamdata for eleven</h4>
                    </div>
                    <div :class="{'col-12 md:col-4': vm.data.student.configuration.nameEnabled, 'col-12' : !vm.data.student.configuration.nameEnabled}" v-if="vm.data.student.configuration.personalIdEnabled">
                        <div class="border-bottom">
                            <p v-if="vm.lang === 'en'">National ID</p>
                            <p v-else-if="vm.lang === 'da_en'">CPR-nummer / National ID</p>
                            <p v-else>CPR-nummer</p>
                            <h4>{{vm.data.student.member.personalIdEncrypted || 'Ikke udfyldt'}}</h4>
                        </div>
                    </div>
                    <div :class="{'col-12 md:col-8': vm.data.student.configuration.personalIdEnabled, 'col-12' : !vm.data.student.configuration.personalIdEnabled}" v-if="vm.data.student.configuration.nameEnabled">
                        <div class="border-bottom">
                            <p v-if="vm.lang === 'en'">Name</p>
                            <p v-else-if="vm.lang === 'da_en'">Fulde navn / Name</p>
                            <p v-else>Fulde navn</p>
                            <h4>{{vm.data.student.member.name || 'Ikke udfyldt'}}</h4>
                        </div>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.addressEnabled">
                        <div class="border-bottom">
                            <p v-if="vm.lang === 'en'">Address</p>
                            <p v-else-if="vm.lang === 'da_en'">Adresse / Address</p>
                            <p v-else>Adresse</p>
                            <h4>{{(vm.data.student.member.address || 'Ikke udfyldt') + ', ' + vm.data.student.member.zip + ' ' + vm.data.student.member.city}} </h4>
                        </div>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.emailEnabled">
                        <div class="border-bottom">
                            <p>E-mail</p>
                            <h4>{{vm.data.student.member.email || 'Ikke udfyldt'}}</h4>
                        </div>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.phoneEnabled">
                        <div class="border-bottom">
                            <p></p>
                            <p v-if="vm.lang === 'en'">Mobile number</p>
                            <p v-else-if="vm.lang === 'da_en'">Mobilnummer / Mobile number</p>
                            <p v-else>Mobilnummer</p>
                            <h4>{{vm.data.student.member.phone || 'Ikke udfyldt'}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h4 v-if="vm.data.headline" class="mb-4 mt-2">{{vm.data.headline}}</h4>
                <h4 v-else-if="vm.lang === 'en'" class="mb-4 mt-2">Basic student information</h4>
                <h4 v-else-if="vm.lang === 'da_en'" class="mb-4 mt-2">Stamdata for eleven / Basic student information</h4>
                <h4 v-else class="mb-4 mt-2">Stamdata for eleven</h4>
                <input id="md_mrd_id" type="hidden" :value="vm.data.student.member.id" />
                <div class="grid">
                    <div :class="{'col-12 md:col-4': vm.data.student.configuration.nameEnabled, 'col-12' : !vm.data.student.configuration.nameEnabled}" v-if="vm.data.student.configuration.personalIdEnabled">
                        <span class="p-float-label">
                            <InputMask id="md_mrd_personalId" type="text" class="p-100 p-filled" v-model="vm.data.student.member.personalIdEncrypted" mask="999999-9999" placeholder="xxxxxx-xxxx" slotChar="x" autocomplete="off" role="presentation" :required="vm.data.student.configuration.personalIdRequired" />
                            <label v-if="vm.lang === 'en'" for="md_mrd_personalId">National ID</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_personalId">CPR-nummer / National ID</label>
                            <label v-else for="md_mrd_personalId">CPR-nummer</label>
                        </span>
                    </div>
                    <div :class="{'col-12 md:col-8': vm.data.student.configuration.personalIdEnabled, 'col-12' : !vm.data.student.configuration.personalIdEnabled}" v-if="vm.data.student.configuration.nameEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_name" type="text" class="p-100" v-model="vm.data.student.member.name" autocomplete="off" role="presentation" :required="vm.data.student.configuration.nameRequired" />
                            <label v-if="vm.lang === 'en'" for="md_mrd_name">Name</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_name">Fulde navn / Name</label>
                            <label v-else for="md_mrd_name">Fulde navn</label>
                        </span>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.addressEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_address" type="text" class="p-100" v-model="vm.data.student.member.address" autocomplete="off" role="presentation" :required="vm.data.student.configuration.addressRequired" />
                            <label v-if="vm.lang === 'en'" for="md_mrd_address">Address</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_address">Adresse / Address</label>
                            <label v-else for="md_mrd_address">Adresse</label>
                        </span>
                    </div>
                    <div class="col-3" v-if="vm.data.student.configuration.addressEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_zip" type="number" class="p-100" v-model="vm.data.student.member.zip" autocomplete="off" role="presentation" :required="vm.data.student.configuration.addressRequired" />

                            <label v-if="vm.lang === 'en'" for="md_mrd_zip">Postal code</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_zip">Postnr / Postal code</label>
                            <label v-else for="md_mrd_zip">Postnr</label>
                        </span>
                    </div>
                    <div class="col-9" v-if="vm.data.student.configuration.addressEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_city" type="text" class="p-100" v-model="vm.data.student.member.city" autocomplete="off" role="presentation" :required="vm.data.student.configuration.addressRequired" />
                            <label v-if="vm.lang === 'en'" for="md_mrd_city">City</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_city">By / City</label>
                            <label v-else for="md_mrd_city">By</label>
                        </span>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.emailEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_email" type="email" class="p-100" v-model="vm.data.student.member.email" autocomplete="off" role="presentation" :required="vm.data.student.configuration.emailRequired" />
                            <label for="md_mrd_email">E-mail</label>
                        </span>
                    </div>
                    <div class="col-12" v-if="vm.data.student.configuration.phoneEnabled">
                        <span class="p-float-label">
                            <InputText id="md_mrd_phone" type="text" class="p-100" v-model="vm.data.student.member.phone" autocomplete="off" role="presentation" :required="vm.data.student.configuration.phoneRequired" />
                            <label v-if="vm.lang === 'en'" for="md_mrd_phone">Mobile number</label>
                            <label v-else-if="vm.lang === 'da_en'" for="md_mrd_phone">Mobilnummer / Mobile number</label>
                            <label v-else for="md_mrd_phone">Mobilnummer</label>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="vm.data.relations">
                <h3 class="mb-0 mt-4">Stamdata for forældre/værger</h3>
                <div v-for="(relation, index) in vm.data.relations" :key="index" class="relative">
                    <Divider class="mb-5">
                        <small class="text-uppercase"><strong>Værge {{ index + 1 }}</strong></small>
                    </Divider>
                    <Button icon="pi pi-trash" class="p-button-remove-guardian p-button-rounded p-button-danger" @click="vm.data.relations.splice(index, 1);" />
                    <input :id="[`md_${index}_id`]" type="hidden" :value="relation.id" />
                    <div class="grid">
                        <div class="col-12 md:col-4">
                            <span class="p-float-label">
                                <InputMask :id="`md_${index}_personalId`" type="text" class="p-100 p-filled" v-model="relation.member.personalIdEncrypted" mask="999999-9999" placeholder="xxxxxx-xxxx" slotChar="x" autocomplete="off" role="presentation" :required="relation.configuration.personalId" />
                                <label :for="`md_${index}_personalId`">CPR-nummer</label>
                            </span>
                        </div>
                        <div class="col-12 md:col-8">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_name`]" type="text" class="p-100" v-model="relation.member.name" autocomplete="off" role="presentation" :required="relation.configuration.name" />
                                <label :for="[`md_${index}_name`]">Navn</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_address`]" type="text" class="p-100" v-model="relation.member.address" autocomplete="off" role="presentation" :required="relation.configuration.address" />
                                <label :for="[`md_${index}_address`]">Adresse</label>
                            </span>
                        </div>
                        <div class="col-3">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_zip`]" type="number" class="p-100" v-model="relation.member.zip" autocomplete="off" role="presentation" :required="relation.configuration.address" />
                                <label :for="[`md_${index}_zip`]">Postnr</label>
                            </span>
                        </div>
                        <div class="col-9">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_city`]" type="text" class="p-100" v-model="relation.member.city" autocomplete="off" role="presentation" :required="relation.configuration.address" />
                                <label :for="[`md_${index}_city`]">By</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_email`]" type="email" class="p-100" v-model="relation.member.email" autocomplete="off" role="presentation" :required="relation.configuration.email" />
                                <label :for="[`md_${index}_email`]">E-mail</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <InputText :id="[`md_${index}_phone`]" type="text" class="p-100" v-model="relation.member.phone" autocomplete="off" role="presentation" :required="relation.configuration.phone" />
                                <label :for="[`md_${index}_phone`]">Mobilnummer</label>
                            </span>
                        </div>
                        <div class="col-12 flex align-items-center">
                            <InputSwitch :inputId="`md_${index}_legalGuardian`" v-model="relation.member.legalGuardian" />
                            <!--<Checkbox v-model="relation.member.legalGuardian" :inputId="`md_${index}_legalGuardian`" :name="`md_${index}_legalGuardian`" value="yes" />-->
                            <label :for="[`md_${index}_legalGuardian`]" class="ml-2"> Har forældremyndighed?</label>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-5">
                    <Button label="Tilføj værge" class="p-button-link p-button-sm" @click="vm.data.relations.push({member:{}, configuration:vm.configuration })" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent, reactive, watch } from 'vue';
    import _ from 'lodash';

    export default defineComponent({
        name: 'MasterData',
        props: {
            data: String,
            locked: String,
            lang: String
        },
        emits: ['callback'],
        setup(props, { emit }) {

            const vm = reactive({
                data: JSON.parse(window.decodeURIComponent(props.data)),
                configuration: {},
                readonly: props.locked === 'true',
                lang: props.lang
            });

            if (vm.data.relations && vm.data.relations[0].configuration) {
                vm.configuration = JSON.parse(JSON.stringify(vm.data.relations[0].configuration));
            }

            watch(vm.data,
                _.throttle(() => {
                    emit('callback', vm.data);
                }, 100), {
                deep: true
            }
            );

            return { vm };
        }
    });
</script>
<style scoped lang="scss">
    .p-button-remove-guardian {
        position: absolute;
        right: 0px;
        top: -15px;
    }

    .master-data-input {
        input[required] + label:after {
            content: '(påkrævet)';
            margin: 0 0.25rem;
            /*            font-size: 20px;
            line-height: 20px;
            vertical-align: middle;*/
        }
    }
</style>
