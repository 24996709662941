<template>
    <div class="page-admin-log">
        <page-header headline="Papirkurv">
            <div class="p-input-icon-left mx-2" style="width:100%;flex:1 1 auto;">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" style="width:100%" placeholder="Søg efter navn..." />
            </div>
        </page-header>
        <div class="content-wrapper">
            <div class="main-content" v-if="vm.initialized">

                <div v-if="vm.items && vm.items.length > 0">
                    <Message severity="info" :closable="false" class="mt-0 mb-5">
                        Papirkurven fungerer som et midlertidigt trin før permanent sletning eller arkivering. Den giver ekstra sikkerhed, så du kan gendanne slettede dokumenter, samtykker, skabeloner, kontakter eller grupper, hvis de ved en fejl bliver slettet. Hvor længe elementerne forbliver i papirkurven, kan indstilles under Indstillinger > Sletteregler (som standard opbevares de i 30 dage).
                    </Message>
                    <DataTable stripedRows
                               :value="vm.items"
                               dataKey="id"
                               sortField="updated" :sortOrder="-1"
                               :paginator="true" :rows="20" :filters="filters"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,20,50]"
                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} dokumenter" responsiveLayout="scroll">
                        <Column class="column-state" field="objectType" headerStyle="width:130px">
                            <template #body="slotProps">
                                <Tag v-if="slotProps.data.objectType === 0" severity="info" value="Skabelon" style="border-radius: 6px 0 0 6px;"></Tag>
                                <Tag v-else-if="slotProps.data.objectType === 1" severity="success" value="Dokument" style="border-radius: 6px 0 0 6px;"></Tag>
                                <Tag v-else-if="slotProps.data.objectType === 3" value="Gruppe" style="background: #313275; border-radius: 6px 0 0 6px;"></Tag>
                                <Tag v-else-if="slotProps.data.objectType === 4" value="Samtykke" style="background: #48a1a0; border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else-if="slotProps.data.objectType === 5" value="Gruppe kontakt" style="border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else value="Kontakt" style="border-radius: 6px 0 0 6px;"></Tag>
                            </template>
                        </Column>
                        <Column field="name" header="Navn" :sortable="true" style="min-width: 12rem">
                            <template #body="slotProps">
                                <div class="flex">
                                    <strong>{{slotProps.data.name}}</strong>
                                </div>
                            </template>
                        </Column>
                        <Column field="updated" header="Slettet" :sortable="true" style="width:230px">
                            <template #body="slotProps">
                                {{$filters.formatDate(slotProps.data.created)}}
                            </template>
                        </Column>
                        <Column field="expiresAt" header="Udløbsdato" :sortable="true" style="width:230px">
                            <template #body="slotProps">
                                <strong v-if="slotProps">
                                    {{$filters.formatDateSimple(slotProps.data.expiresAt)}}

                                </strong>
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggle($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="itemExtraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <Message severity="info" :closable="false" v-else>
                    Papirkurven er tom
                </Message>
            </div>
        </div>
        <ConfirmDialog group="confirmDelete" :style="{'max-width': '600px', width: '100%'}">
            <div>Er du sikker på at du vil slette <b>{{vm.currentItem.name}}</b>?</div>
            <Message severity="info" :closable="false">Dokumenter og samtykker arkiveres i henhold til slettereglerne under "Indstillinger". Alt andet slettes permanent.</Message>
        </ConfirmDialog>
        <div v-if="vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, reactive } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import api from './../../composables/api';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from "primevue/useconfirm";

    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const confirm = useConfirm();
    const filters = ref({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const vm = reactive({
        items: [],
        busy: false,
        initialized: false
    });

    const itemExtraActions = ref([
        {
            label: 'Gendan',
            icon: 'pi pi-undo',
            command: () => {
                fn.restore(vm.currentItem);
                vm.visibleMenuId = null;
            }
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                confirm.require({
                    group: 'confirmDelete',
                    header: 'Bekræft slet',
                    acceptLabel: 'Ja, slet',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, fortryd',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => {
                        fn.delete(vm.currentItem);
                    }
                });
                vm.visibleMenuId = null;
            }
        }
    ]);

    const fn = {};
    fn.toggle = (event, data) => {
        vm.currentItem = data;
        vm.visibleMenuId = vm.currentItem.id === vm.visibleMenuId ? null : vm.currentItem.id;
    };
    fn.delete = (item) => {
        vm.busy = true;
        api.del(apiUrl + 'recyclebin/items/' + item.id).then(() => {
            const index = vm.items.findIndex((obj => obj.id === vm.currentItem.id));
            vm.items.splice(index, 1);

            // TODO: Temp. fix
            // When deleting a contact we also remove the contact group if any in the BE.
            // Remove the contact group item from the list in FE
            if (vm.currentItem.objectType === 2) {
                vm.items = vm.items.filter(function (item) {
                    return item.objectType !== 5 || (item.objectType === 5 && item.name.indexOf(vm.currentItem.name) === -1);
                });
            }

            toast.add({ severity: 'success', summary: 'Gennemført', detail: item.name + ' slettet', life: 3000 });
            vm.busy = false;
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
            vm.busy = false;
        });
    };
    fn.restore = (object) => {
        if (object && object.id) {
            api.postJson(apiUrl + 'recyclebin/items/' + object.id + '?action=restore').then((restoredItems) => {
                if (restoredItems) {
                    vm.items = vm.items.filter(function (item) {
                        return !restoredItems.some(function (restoredItem) {
                            return restoredItem.id === item.id;
                        });
                    });
                }

                var message = 'Skabelon';
                if (object.objectType === 1) {
                    message = 'Dokument';
                } else if (object.objectType === 2 || object.objectType === 5) {
                    message = 'Kontakt';
                } else if (object.objectType === 3) {
                    message = 'Gruppe';
                } else if (object.objectType === 4) {
                    message = 'Samtykke';
                }

                toast.add({ severity: 'success', summary: message + ' gendannet.', detail: message + ' "' + object.name + '" er blevet gendannet', life: 8000 });

            }).catch((error) => {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
            });
        }
    }

    // Init
    api.get(apiUrl + 'recyclebin/items').then((response) => {
        if (response) {
            vm.items = response;
        } else {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: 'response empty' });
        }
        vm.initialized = true;
    }).catch((error) => {
        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
    });

</script>