<template>
    <header class="header">
        <Button v-if="enableBackButton"
                icon="pi pi-chevron-left"
                class="p-button-rounded p-button-text mr-2 d-print-none"
                @click="back()" />
        <h1 class="headline text-truncate" v-html="headline"></h1>
        <slot></slot>
    </header>
</template>

<script>
    export default {
        name: 'page-header',
        props: {
            headline: String,
            enableBackButton: Boolean
        },
        methods: {
            back() {
                this.$emit('backAction')
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
