// GLOBAL FILTERS
import moment from 'moment'
import 'moment/locale/da'

export default function(app) {
    app.config.globalProperties.$filters = {
        formatDate(value) {
            if (value) {
                return moment(value).local('da').format('DD-MM-YYYY [-] HH:mm:ss');
            }
            return value;
        },
        formatDateHtml(value) {
            if (value) {
                return '<small class="font-italic">' + moment(value).local('da').format('DD-MM-YYYY <br> HH:mm:ss') + '</small>';
            }
            return value;
        },
        formatDateSimple(value) {
            if (value) {
                return moment(value).local('da').format('DD-MM-YYYY');
            }
            return value;
        },
        isCprValid(cpr) {
            if (cpr) {
                cpr = cpr.replace(/[^\d]/g, '');
                if (cpr.length === 10) {
                    return modulo11(cpr) === 0;
                }
            }
            return false;
        }
    }

    var multipliers = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    function sumProduct(a, b) {
        return a.reduce(function(sum, digit, i) { return sum + digit * b[i] }, 0);
    }
    function modulo11(cpr) {
        return sumProduct(cpr.split(''), multipliers) % 11;
    }

}