<template>
    <div class="page-members">
        <page-header headline="Kontakter"></page-header>
        <div class="content-wrapper fixed-height">
            <div class="main-content box flex p-0 overflow-hidden">
                <transition name="fade">
                    <div v-if="vm.secondaryNavIsBusy" class="secondary-nav">
                        <div class="secondary-nav-title">Grupper</div>
                        <div class="secondary-nav-item">
                            <Skeleton></Skeleton>
                        </div>
                        <div class="secondary-nav-item">
                            <Skeleton></Skeleton>
                        </div>
                        <div class="secondary-nav-item">
                            <Skeleton></Skeleton>
                        </div>
                        <div class="secondary-nav-item">
                            <Skeleton></Skeleton>
                        </div>
                    </div>
                    <div class="secondary-nav overflow-y-auto" v-else>
                        <div class="bg-white secondary-nav-title sticky top-0 z-1">Grupper</div>
                        <router-link v-for="memberGroup in vm.memberGroups"
                                     :class="[{ 'active': vm.selectedMemberGroup && memberGroup.id === vm.selectedMemberGroup.id }, 'secondary-nav-item block']"
                                     :key="memberGroup.id"
                                     :to="{name:'AdminMemberId', params: {id: memberGroup.id}}">
                            <strong v-if="memberGroup.type !== 'DEFAULT'" :class="[{ 'opacity-70': !memberGroup.isActive || memberGroup.type === 'DEFAULT' }, 'pr-3']">{{memberGroup.name}}</strong>
                            <strong class="opacity-70" v-else>* Kontakter uden gruppe *</strong>
                            <div v-if="memberGroup.isActive === false" class="faded">(Gruppe deaktiveret)</div>

                            <div class="faded">{{memberGroup.activeMembersCount || 0}} <span v-if="memberGroup.activeMembersCount === 0 || memberGroup.activeMembersCount > 1">kontakter</span><span v-else>kontakt</span></div>
                            <div class="faded" v-if="memberGroup.inactiveMembersCount">{{memberGroup.inactiveMembersCount}} <span v-if="memberGroup.inactiveMembersCount > 1">inaktive kontakter</span><span v-else>inaktiv kontakt</span></div>
                            <div v-if="vm.selectedMemberGroup && vm.selectedMemberGroup.type !== 'DEFAULT'">
                                <Button class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" icon="pi pi-ellipsis-h" @click="fn.toggleMemberGroupMenu($event, memberGroup.id)" aria-haspopup="true" :aria-controls="'overlay_menu_' + memberGroup.id" />
                                <Menu ref="memberGroupMenu" :id="'overlay_menu_' + memberGroup.id" class="kebab-slide-out-menu" :model="memberGroupExtraActions" :popup="false" :class="{'block' : vm.visibleMemberGroupId === memberGroup.id }" />
                                <div v-if="vm.visibleMemberGroupId === memberGroup.id" class="fixed bottom-0 left-0 right-0 top-0 z-0" @click="vm.visibleMemberGroupId = null"></div>
                            </div>
                            <i v-if="memberGroup.isActive === false" class="absolute bottom-0 mb-3 mr-2 mr-3 pi pi-eye-slash right-0 text-2xl opacity-50"></i>
                        </router-link>
                        <div class="pt-4 text-center">
                            <Button label="Opret ny gruppe" icon="pi pi-plus" class="p-button-success" @click="fn.showCreateMemberGroup()" />
                        </div>

                    </div>
                </transition>
                <div class="relative w-full overflow-y-auto" v-if="vm.selectedMemberGroup">
                    <DataTable ref="dt" stripedRows
                               :value="vm.memberGroupMembers"
                               v-model:expandedRows="vm.expandedRows"
                               dataKey="id"
                               sortField="firstname" :sortOrder="1"
                               :paginator="false" :rows="10" :filters="filters"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} kontakter" responsiveLayout="scroll"
                               class="members"
                               :rowClass="({ isActive }) => !isActive ? 'text-400': null">
                        <template #header>
                            <div class="table-header flex flex-column p-2" v-if="vm.selectedMemberGroup">
                                <div class="flex align-items-center justify-content-between w-full mt-2 mb-3">
                                    <h3 class="align-items-center flex mt-2 mb-1 ml-4">
                                        <span>{{vm.selectedMemberGroup.name}}</span>
                                        <span v-if="!vm.selectedMemberGroup.isActive" class="ml-2 opacity-50">(Deaktiveret)</span>
                                    </h3>
                                </div>
                                <div class="p-menubar p-component" style="width: 100%;">
                                    <ul class="p-menubar-root-list" role="menubar">
                                        <li role="none" class="p-menuitem">
                                            <Button v-if="vm.selectedMemberGroup.isActive && vm.selectedMemberGroup.type !== 'DEFAULT'" label="Opret ny kontakt" icon="pi pi-fw pi-user" class="p-menuitem-link p-button-text" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" @click="fn.openMemberGroupMemberSidebar()" />
                                        </li>

                                        <li role="none" class="p-menuitem">
                                            <button v-if="vm.selectedMemberGroup.isActive && vm.selectedMemberGroup.type !== 'DEFAULT'" type="button" class="p-menuitem-link p-button p-button-text" aria-haspopup="true" :aria-expanded="vm.menuImportExpanded" role="menuitem" tabindex="0" @click="fn.toggleMenuImport()">
                                                <span class="p-menuitem-icon pi pi-fw pi-users"></span>
                                                <span class="p-menuitem-text">Importér kontakter</span>
                                                <span class="p-submenu-icon pi pi-angle-down"></span>
                                            </button>
                                            <ul class="p-submenu-list" role="menu" :class="{'block': vm.menuImportExpanded}">
                                                <li role="none" class="p-menuitem">
                                                    <FileUpload class="p-menuitem-link" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" mode="basic" name="upload[]" accept=".csv" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onImportMembersUpload('importmemberskomit', $event)" :auto="true" chooseLabel="KOMiT csv fil" />
                                                </li>
                                                <li role="none" class="p-menuitem">
                                                    <FileUpload class="p-menuitem-link" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" mode="basic" name="upload[]" accept=".csv" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onImportMembersUpload('importmembersuv', $event)" :auto="true" chooseLabel="UVdata csv fil" />
                                                </li>
                                                <li role="none" class="p-menuitem">
                                                    <FileUpload class="p-button-link p-menuitem-link" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" mode="basic" name="simpleUpload[]" accept=".csv" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onImportMembersUpload('importmembersminimal', $event)" :auto="true" chooseLabel="Skoleadmin csv fil" />
                                                </li>
                                            </ul>
                                        </li>
                                        <li role="none" class="p-menuitem">
                                            <Button v-if="vm.selectedMemberGroup.type !== 'DEFAULT'" label="Eksportér kontakter" icon="pi pi-fw pi-download" class="p-menuitem-link p-button-text" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" @click="fn.exportMembersInMemberGroup(vm.selectedMemberGroup)" />
                                        </li>
                                    </ul>
                                    <div class="p-menubar-end">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filters['global'].value" placeholder="Søg efter kontakt" />
                                        </span>
                                    </div>
                                </div>
                                <div v-if="vm.menuImportExpanded" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.menuImportExpanded = false"></div>
                            </div>
                            <Message severity="error" class="mx-3" v-if="vm.importError && vm.importResult.errors && vm.importResult.errors.length">
                                <strong>Fejl:</strong><br />
                                <ul class="mt-2 ml-5">
                                    <li class="mb-1" v-for="(msg, index) in vm.importResult.errors" :key="index">{{msg}}</li>
                                </ul>
                            </Message>
                            <Message severity="warn" class="mx-3" v-if="vm.importError && vm.importResult.warnings && vm.importResult.warnings.length">
                                <strong>Advarsel:</strong><br />
                                <ul class="mt-2 ml-5">
                                    <li class="mb-1" v-for="(msg, index) in vm.importResult.warnings" :key="index">{{msg}}</li>
                                </ul>
                            </Message>
                            <Message severity="info" class="mx-3" v-if="vm.importResult.info && vm.importResult.info.length">
                                <strong>Info:</strong><br />
                                <ul class="mt-2 ml-5">
                                    <li class="mb-1" v-for="(msg, index) in vm.importResult.info" :key="index">{{msg}}</li>
                                </ul>
                            </Message>
                        </template>
                        <Column class="column-state" headerStyle="width:65px">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.isActive === false" class="icon-wrapper info" v-tooltip.top="'Kontakten er deaktiveret'"><i class="pi pi-eye-slash"></i></div>
                                <div v-else-if="slotProps.data.hasMoreThanTwoRelations" class="icon-wrapper error" v-tooltip.top="'Eleven har mere end 2 relationer. Er dette en fejl? Hvis du genbruger elevnumre, har du husket at slette den gamle elev, før du importerede den nye?'"><i class="pi pi-question"></i></div>
                                <div v-else-if="slotProps.data.isMemberEmailBounced && slotProps.data.isAnyMemberRelationEmailBounced" class="icon-wrapper error" v-tooltip.top="'Afsendelse til eleven og en eller flere af deres relationer mislykkedes'"><i class="pi pi-times-circle"></i></div>
                                <div v-else-if="slotProps.data.isMemberEmailBounced" class="icon-wrapper error" v-tooltip.top="'Afsendelse til eleven mislykkedes'"><i class="pi pi-times-circle"></i></div>
                                <div v-else-if="slotProps.data.isAnyMemberRelationEmailBounced" class="icon-wrapper error" v-tooltip.top="'Afsendelse til en eller flere relationer mislykkedes'"><i class="pi pi-times-circle"></i></div>
                                <div v-else-if="slotProps.data.isAnyMemberRelationEmailEmpty" class="icon-wrapper warning" v-tooltip.top="'En eller flere relationer mangler e-mail'"><i class="pi pi-exclamation-triangle"></i></div>
                            </template>
                        </Column>
                        <Column field="externalId" header="Id" :sortable="true" headerStyle="width:80px">
                            <template #body="slotProps">
                                <strong class="clickable" @click="fn.goToMemberDetails(slotProps.data)">{{slotProps.data.externalId}}</strong>
                            </template>
                        </Column>
                        <Column field="firstname" header="Fornavn" :sortable="true">
                            <template #body="slotProps">
                                <strong class="clickable" :class="{'text-400': slotProps.data.isActive === false}" @click="fn.goToMemberDetails(slotProps.data)">{{slotProps.data.firstname}}</strong>
                            </template>
                        </Column>
                        <Column field="lastname" header="Efternavn" :sortable="true">
                            <template #body="slotProps">
                                <strong class="clickable" :class="{'text-400': slotProps.data.isActive === false}" @click="fn.goToMemberDetails(slotProps.data)">{{slotProps.data.lastname}}</strong>
                            </template>
                        </Column>
                        <Column field="email" header="E-mail" :sortable="false">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.email">{{slotProps.data.email}}</span>
                                <span v-else class="opacity-40">Ikke udfyldt</span>
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggleMemberActions($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="memberExtraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                    <Sidebar v-model:visible="vm.showMemberDetails" position="right" class="p-sidebar-lg">
                        <div class="member-details relative">
                            <h2 class="pb-4" style=" font-weight: 500;">
                                Detaljer for <strong>{{vm.memberGroupMember.firstname}} {{vm.memberGroupMember.lastname}}</strong>
                            </h2>
                            <div class="grid">
                                <div class="col-12 md:col-8 flex flex-column">
                                    <div class="align-items-center flex mb-2">
                                        <div class="flex align-items-center">
                                            <i class="pi pi-database mr-2" style="font-size: 28px;"></i>
                                            <h4 class="mb-0">Stamdata</h4>
                                        </div>
                                        <Button icon="pi pi-pencil" class="p-button-sm p-button-link" label="Rediger" @click="fn.openMemberGroupMemberSidebar(vm.memberGroupMember, 'MEMBER')" />
                                    </div>
                                    <div class="box mt-2 flex-grow-1">
                                        <div class="grid">
                                            <div v-if="vm.memberGroupMember.externalGroupId" class="label col-6 sm:col-2">Gruppe id</div>
                                            <div v-if="vm.memberGroupMember.externalGroupId" class="col-6 sm:col-10">{{vm.memberGroupMember.externalGroupId}}</div>
                                            <div class="label col-6 sm:col-2">Id</div>
                                            <div class="col-6 sm:col-10">{{vm.memberGroupMember.externalId}}</div>
                                            <div class="label col-6 sm:col-2">CPR-nummer</div>
                                            <div class="col-6 sm:col-10">
                                                <span v-if="vm.memberGroupMember.personalIdEncrypted">{{vm.memberGroupMember.personalIdEncrypted}}</span>
                                                <span v-else class="opacity-40">Ikke udfyldt</span>
                                            </div>
                                            <div class="label col-6 sm:col-2">Klassetrin</div>
                                            <div class="col-6 sm:col-10">{{vm.memberGroupMember.className || '-'}}</div>
                                            <div class="label col-6 sm:col-2">Adresse</div>
                                            <div class="col-6 sm:col-10">
                                                <span v-if="vm.memberGroupMember.address">{{vm.memberGroupMember.address}}, {{vm.memberGroupMember.zip}} {{vm.memberGroupMember.city}}</span>
                                                <span v-else class="opacity-40">Ikke udfyldt</span>
                                            </div>
                                            <div class="label col-6 sm:col-2">E-mail</div>
                                            <div class="col-6 sm:col-10">
                                                <i v-if="vm.memberGroupMember.email && vm.memberGroupMember.emailHasBounced" v-tooltip.top="vm.memberGroupMember.emailBounceMessage" class="pi pi-times-circle mr-1" style="color: #EF4444;"></i>
                                                <span v-if="vm.memberGroupMember.email">{{vm.memberGroupMember.email}}</span>
                                                <span v-else class="opacity-40">Ikke udfyldt</span>
                                            </div>
                                            <div class="label col-6 sm:col-2">Telefon</div>
                                            <div class="col-6 sm:col-10">
                                                <span v-if="vm.memberGroupMember.phone">{{vm.memberGroupMember.phone}}</span>
                                                <span v-else class="opacity-40">Ikke udfyldt</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 md:col-4 flex flex-column">
                                    <div class="align-items-center flex mb-2 justify-content-between">
                                        <div class="flex align-items-center">
                                            <i class="pi pi-comment mr-2" style="font-size: 28px;"></i>
                                            <h4 class="mb-0">Noter</h4>
                                        </div>
                                        <Button icon="pi pi-plus-circle" class="p-button-sm p-button-link" label="Ny note" @click="vm.slideInMemberGroupMemberNote = true" />
                                    </div>
                                    <div class="box mt-2 flex-grow-1">
                                        <div v-if="latestNote" class="flex flex-column h-full">
                                            <div class="label">
                                                {{ $filters.formatDateSimple(latestNote.created) }} ({{ latestNote.systemUser }})
                                            </div>
                                            <div class="mt-1" style="white-space: pre-line; word-break: break-word;">{{ latestNote.note }}</div>
                                            <Button v-if="vm.memberGroupMember.notes.length > 1" :label="'Se alle noter (' + vm.memberGroupMember.notes.length + ')'" class="mt-auto text-sm" text @click="vm.slideInMemberGroupMemberNote = true"></Button>
                                        </div>
                                        <span v-else class="opacity-40">Ingen noter</span>
                                    </div>
                                </div>
                            </div>

                            <div class="my-6">
                                <div class="align-items-center flex mb-2">
                                    <div class="flex align-items-center">
                                        <i class="pi pi-users mr-2" style="font-size: 28px;"></i>
                                        <h4 class="mb-0">Relationer</h4>
                                    </div>
                                    <Button icon="pi pi-pencil" class="p-button-sm p-button-link" label="Rediger" @click="fn.openMemberGroupMemberSidebar(vm.memberGroupMember, 'MEMBER_RELATIONS')" />
                                </div>
                                <DataTable v-if="vm.memberGroupMember.memberRelations && vm.memberGroupMember.memberRelations.length"
                                           :value="vm.memberGroupMember.memberRelations"
                                           responsiveLayout="scroll"
                                           :paginator="vm.memberGroupMember.memberRelations.length > 5" :rows="5"
                                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                           currentPageReportTemplate="Viser {first} til {last} af {totalRecords} relationer"
                                           sortField="created" :sortOrder="-1"
                                           class="box mt-2 pt-0">
                                    <Column field="relatedMember.personalIdEncrypted" header="CPR-nummer" headerStyle="width:135px;">
                                        <template #body="slotProps">
                                            <span v-if="slotProps.data.relatedMember.personalIdEncrypted">{{slotProps.data.relatedMember.personalIdEncrypted}}</span>
                                            <span v-else class="opacity-40">Ikke udfyldt</span>
                                        </template>
                                    </Column>
                                    <Column header="Relation">
                                        <template #body="slotProps">
                                            {{slotProps.data.relatedMember.firstname}} {{slotProps.data.relatedMember.lastname}}
                                            <div v-if="slotProps.data.relatedMember.address">
                                                {{slotProps.data.relatedMember.address}},<br />
                                                {{slotProps.data.relatedMember.zip}} {{slotProps.data.relatedMember.city}}
                                            </div>
                                        </template>
                                    </Column>

                                    <Column field="relatedMember.email" header="E-mail">
                                        <template #body="slotProps">
                                            <i v-if="slotProps.data.relatedMember.emailHasBounced" v-tooltip.top="slotProps.data.relatedMember.emailBounceMessage" class="pi pi-times-circle mr-1" style="color: #EF4444;"></i>
                                            <span v-if="slotProps.data.relatedMember.email">{{slotProps.data.relatedMember.email}}</span>
                                            <span v-else class="opacity-40">Ikke udfyldt</span>
                                        </template>
                                    </Column>
                                    <Column field="relatedMember.phone" header="Telefon">
                                        <template #body="slotProps">
                                            <span v-if="slotProps.data.relatedMember.phone">{{slotProps.data.relatedMember.phone}}</span>
                                            <span v-else class="opacity-40">Ikke udfyldt</span>
                                        </template>
                                    </Column>
                                    <Column field="relatedMember.receivesStudentGrantDocument" header="Modtager statsstøtte- skemaet" headerStyle="text-align:center;width:130px" class="text-center">
                                        <template #body="slotProps">
                                            <div v-if="typeof slotProps.data.relatedMember.receivesStudentGrantDocument === 'boolean'">
                                                <i v-if="slotProps.data.relatedMember.receivesStudentGrantDocument" class="pi pi-check"></i>
                                                <i v-else class="pi pi-times" v-tooltip.top="'Relation har anden adresse end eleven'"></i>
                                            </div>
                                            <div v-else>
                                                <i v-if="!vm.memberGroupMember.address || vm.memberGroupMember.address == slotProps.data.relatedMember.address" class="pi pi-check"></i>
                                                <i v-else class="pi pi-times" v-tooltip.top="'Relation har anden adresse end eleven'"></i>
                                            </div>
                                        </template>
                                    </Column>

                                </DataTable>
                                <div v-else class="pt-3">
                                    Ingen relationer
                                </div>
                            </div>
                            <div class="view-consents my-6">
                                <div class="flex align-items-center">
                                    <i class="pi pi-check-square mr-2" style="font-size: 28px;"></i>
                                    <h4 class="mb-0">Samtykker</h4>
                                </div>
                                <div v-if="!vm.recivingExtraMemberData">
                                    <DataTable v-if="vm.memberGroupMember.consentAnswers && vm.memberGroupMember.consentAnswers.length"
                                               :value="vm.memberGroupMember.consentAnswers"
                                               responsiveLayout="scroll"
                                               :paginator="vm.memberGroupMember.consentAnswers.length > 5" :rows="5"
                                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} afsendte samtykker"
                                               sortField="created" :sortOrder="-1"
                                               class="box mt-3">
                                        <Column field="consent.name" header="Samtykke" sortable></Column>
                                        <Column field="created" header="Opdateret" :sortable="true" headerStyle="width:230px">
                                            <template #body="slotProps">
                                                {{$filters.formatDate(slotProps.data.updated)}}
                                            </template>
                                        </Column>
                                        <Column headerStyle="width:140px" header="Status" dataType="boolean" class="column-state column-last">
                                            <template #body="slotProps">
                                                <Tag v-if="slotProps.data.answer === null" severity="info" value="Ikke besvaret"></Tag>
                                                <Tag v-if="slotProps.data.answer" severity="success" value="Ja"></Tag>
                                                <Tag v-if="slotProps.data.answer !== null && !slotProps.data.answer" severity="danger" value="Nej"></Tag>
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <div v-else class="pt-3">
                                        Ingen samtykker
                                    </div>
                                </div>
                                <div v-else class="mt-3 box">
                                    <DataTable :value="vm.mockContent" responsiveLayout="scroll">
                                        <Column field="code" header="Samtykke">
                                            <template #body>
                                                <Skeleton></Skeleton>
                                            </template>
                                        </Column>
                                        <Column field="name" header="Oprettet">
                                            <template #body>
                                                <Skeleton></Skeleton>
                                            </template>
                                        </Column>
                                        <Column field="category" headerStyle="width:140px" header="">
                                            <template #body>
                                                <Skeleton></Skeleton>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div class="pb-3">
                                <div>
                                    <div v-if="!vm.recivingExtraMemberData" class="my-6">
                                        <div v-if="!vm.memberGroupMember.completedDocuments.length && !vm.memberGroupMember.pendingDocuments.length">
                                            <div class="flex align-items-center justify-content-between">
                                                <div class="flex align-items-center">
                                                    <i class="pi pi-file-o mr-2" style="font-size: 28px;"></i>
                                                    <h4 class="mb-0">Dokumenter & filer</h4>
                                                </div>
                                                <div class="member-file-uploader">
                                                    <FileUpload mode="basic" name="uploadFile[]" accept="image/*,application/pdf" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onMemberFileUpload(vm.memberGroupMember, $event)" :auto="true" chooseLabel="Upload fil" />
                                                </div>
                                            </div>
                                            <div class="pt-3">
                                                Ingen dokumenter
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="my-6 box">
                                        <DataTable :value="vm.mockContent" responsiveLayout="scroll">
                                            <Column field="code" header="Dokument">
                                                <template #body>
                                                    <Skeleton></Skeleton>
                                                </template>
                                            </Column>
                                            <Column field="name" header="Oprettet">
                                                <template #body>
                                                    <Skeleton></Skeleton>
                                                </template>
                                            </Column>
                                            <Column field="category" headerStyle="width:140px" header="">
                                                <template #body>
                                                    <Skeleton></Skeleton>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div class="my-6" v-if="vm.memberGroupMember.pendingDocuments && vm.memberGroupMember.pendingDocuments.length">
                                    <div class="flex align-items-center mb-2">
                                        <i class="pi pi-file-o mr-2" style="font-size: 28px;"></i>
                                        <h4 class="mb-0">Afsendte dokumenter</h4>
                                    </div>
                                    <div class="member-files box mt-3">
                                        <DataTable :value="vm.memberGroupMember.pendingDocuments"
                                                   responsiveLayout="scroll"
                                                   :paginator="vm.memberGroupMember.pendingDocuments.length > 5" :rows="5"
                                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                                   currentPageReportTemplate="Viser {first} til {last} af {totalRecords} afsendte dokumenter"
                                                   sortField="created" :sortOrder="-1">
                                            <Column field="documentTemplateName" header="Dokument" :sortable="true">
                                                <template #body="slotProps">
                                                    {{slotProps.data.documentTemplateName}}
                                                </template>
                                            </Column>
                                            <Column field="created" header="Oprettet" :sortable="true" headerStyle="width:230px">
                                                <template #body="slotProps">
                                                    {{$filters.formatDate(slotProps.data.created)}}
                                                </template>
                                            </Column>
                                            <Column field="state" header="Status" sortable class="text-center column-state" style="width:125px">
                                                <template #body="slotProps">
                                                    <Tag v-if="slotProps.data.state === 6" severity="success" value="Afsluttet"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 5" value="Klar til afslutning"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 4" severity="warning" value="Afventer godkendelse"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 3 || slotProps.data.state === 10" value="Igang"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 1" severity="info" value="Afsendt"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 2" severity="info" value="Gemt"></Tag>
                                                    <Tag v-else-if="slotProps.data.state === 7" severity="danger" value="Afvist"></Tag>
                                                    <Tag v-else severity="info">Ukendt</Tag>
                                                </template>
                                            </Column>
                                            <Column headerStyle="width:80px" style="padding: 0.5rem 1rem;">
                                                <template #body="slotProps">
                                                    <Button icon="pi pi-trash" class="p-button-danger" v-tooltip.top="'Slet dokument'" @click="fn.confirmDeleteDocument(slotProps.data)" />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div class="mt-6" v-if="vm.memberGroupMember.completedDocuments && vm.memberGroupMember.completedDocuments.length">
                                    <div class="flex align-items-center justify-content-between">
                                        <div class="flex align-items-center">
                                            <i class="pi pi-copy mr-2" style="font-size: 28px;"></i>
                                            <h4 class="mb-0">Afsluttede dokumenter & filer</h4>
                                        </div>
                                        <div class="member-file-uploader">
                                            <FileUpload mode="basic" name="uploadFile[]" accept="image/*,application/pdf" :maxFileSize="1000000" :customUpload="true" @uploader="fn.onMemberFileUpload(vm.memberGroupMember, $event)" :auto="true" chooseLabel="Upload fil" />
                                        </div>
                                    </div>
                                    <div class="member-files box mt-3">
                                        <DataTable :value="vm.memberGroupMember.completedDocuments"
                                                   v-model:expandedRows="expandedRows"
                                                   responsiveLayout="scroll"
                                                   :paginator="vm.memberGroupMember.completedDocuments.length > 10" :rows="10"
                                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                                   currentPageReportTemplate="Viser {first} til {last} af {totalRecords} fuldførte dokumenter"
                                                   :row-class="fn.rowClass"
                                                   sortField="created" :sortOrder="-1">
                                            <Column :expander="true" headerClass="expander" style="width:65px;padding-left:1rem" />

                                            <Column field="documentTemplateName" header="Dokument" :sortable="true">
                                                <template #body="slotProps">
                                                    <span v-if="slotProps.data.title">{{slotProps.data.title}}</span>
                                                    <span v-else>{{slotProps.data.documentTemplateName}}</span>
                                                </template>
                                            </Column>

                                            <Column field="created" header="Oprettet" :sortable="true" headerStyle="width:230px">
                                                <template #body="slotProps">
                                                    {{$filters.formatDate(slotProps.data.created)}}
                                                </template>
                                            </Column>
                                            <Column headerStyle="width:248px" class="text-right" style="padding: 0.5rem 1rem;">
                                                <template #body="slotProps">
                                                    <Button v-if="slotProps.data.documentAssets && slotProps.data.documentAssets.length > 0" icon="pi pi-paperclip" class="p-button-success mr-2" v-tooltip.top="'Hent dokument med filer'" @click="fn.downloadMergedDocument(slotProps.data)" />
                                                    <Button icon="pi pi-download" class="p-button-success mr-2" v-tooltip.top="'Hent dokument'" @click="fn.downloadDocument(slotProps.data)" />
                                                    <Button icon="pi pi-eye" class="mr-2" v-tooltip.top="'Se dokument'" @click="fn.goToDocument(slotProps.data)" />
                                                    <Button icon="pi pi-trash" class="p-button-danger" v-tooltip.top="'Slet dokument'" @click="fn.confirmDeleteDocument(slotProps.data)" />
                                                </template>
                                            </Column>
                                            <template #expansion="slotProps">
                                                <div>
                                                    <h5 class="mb-0">Ekstra dokumentation uploadet</h5>
                                                    <DataTable :value="slotProps.data.documentAssets" responsiveLayout="scroll">
                                                        <Column field="title" header="Filnavn"></Column>
                                                        <Column field="contentType" header="Type"></Column>
                                                        <Column header="Antivirus-scanning" headerStyle="width:80px" class="text-center">
                                                            <template #body="slotProps">
                                                                <div v-if="!slotProps.data.scanningState">
                                                                    <i class="border-circle p-1 pi pi-clock text-white" style="background: #3B82F6; font-size: 20px; " v-tooltip.top="'Filen afventer antivirusscanning'"></i>
                                                                </div>
                                                                <div v-else>
                                                                    <div v-if="slotProps.data.scanningResult === 0">
                                                                        <i class="border-circle p-1 pi pi-check text-white" style="background: #39bc6c;font-size: 20px;" v-tooltip.top="'Ingen virus fundet'"></i>
                                                                    </div>
                                                                    <div v-else>
                                                                        <i class="border-circle p-1 pi pi-minus text-white" style="background: #ef4444; font-size: 20px;" v-tooltip.top="'Virus fundet i filen'"></i>

                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </Column>
                                                        <Column headerStyle="width:80px" style="padding: 0.5rem 1rem;">
                                                            <template #body="slotProps">
                                                                <Button icon="pi pi-download" class="p-button-success" v-tooltip.top="'Hent dokument'" @click="fn.downloadDocumentAsset(slotProps.data)" />
                                                            </template>
                                                        </Column>
                                                    </DataTable>

                                                </div>
                                            </template>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                            <div v-if="vm.deletingDocument || vm.downloadingDocument" class="absolute overlay overlay-submitted">
                                <ProgressSpinner />
                            </div>
                        </div>
                    </Sidebar>
                    <div v-if="vm.recivingData" class="absolute overlay overlay-submitted">
                        <ProgressSpinner />
                    </div>
                </div>
                <div v-else class="w-full">
                    <Message severity="info" :closable="false" class="m-3">
                        Du har endnu ikke oprettet nogen kontakter. Opret en ny gruppe for at oprette eller importere kontakter
                    </Message>
                </div>
                <Sidebar v-model:visible="vm.slideInMemberGroupMember" position="right" class="p-sidebar-md">
                    <div class="h-full flex flex-column">
                        <h3 class="pb-2">
                            <span v-if="vm.isMemberGroupMemberEdit">Rediger</span>
                            <span v-else>Opret ny kontakt</span>
                        </h3>
                        <div class="p-formgrid">
                            <div v-if="vm.slideInMemberGroupMemberShowMember" class="grid box mt-2 mx-0">
                                <div class="col-12"><strong class="opacity-70">Personlige oplysninger</strong></div>
                                <div class="col-6">
                                    <span class="p-float-label">
                                        <InputText v-if="vm.isMemberGroupMemberEdit" id="inputid" type="text" class="p-100" v-model="vm.memberGroupMember.externalGroupId" disabled />
                                        <InputText v-else id="inputid" type="text" class="p-100" v-model="vm.memberGroupMember.externalGroupId" />
                                        <label for="inputid">Gruppe Id (Valgfrit)</label>
                                    </span>
                                </div>
                                <div class="col-6">
                                    <span class="p-float-label">
                                        <InputText v-if="vm.isMemberGroupMemberEdit" id="inputid" type="text" class="p-100" v-model="vm.memberGroupMember.externalId" disabled />
                                        <InputText v-else id="inputid" type="text" class="p-100" v-model="vm.memberGroupMember.externalId" />
                                        <label for="inputid">Id (Valgfrit)</label>
                                    </span>
                                </div>
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText id="inputpersonalid" type="text" class="p-100" v-model="vm.memberGroupMember.personalIdEncrypted" />
                                        <label for="inputpersonalid">CPR-nummer</label>
                                    </span>
                                </div>
                                <div class="col-5">
                                    <span class="p-float-label">
                                        <InputText id="inputfirstname" type="text" class="p-100" v-model="vm.memberGroupMember.firstname" />
                                        <label for="inputfirstname">Fornavn</label>
                                    </span>
                                </div>
                                <div class="col-7">
                                    <span class="p-float-label">
                                        <InputText id="inputlastname" type="text" class="p-100" v-model="vm.memberGroupMember.lastname" />
                                        <label for="inputlastname">Efternavn</label>
                                    </span>
                                </div>
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText id="inputpersonalid" type="text" class="p-100" v-model="vm.memberGroupMember.className" />
                                        <label for="inputpersonalid">Klassetrin</label>
                                    </span>
                                </div>

                                <div class="col-12"><strong class="opacity-70">Kontakt oplysninger</strong></div>
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText id="inputaddress" type="text" class="p-100" v-model="vm.memberGroupMember.address" />
                                        <label for="inputaddress">Adresse</label>
                                    </span>
                                </div>
                                <div class="col-3">
                                    <span class="p-float-label">
                                        <InputText id="inputzip" type="number" class="p-100" v-model="vm.memberGroupMember.zip" />
                                        <label for="inputzip">Postnr</label>
                                    </span>
                                </div>
                                <div class="col-9">
                                    <span class="p-float-label">
                                        <InputText id="inputcity" type="text" class="p-100" v-model="vm.memberGroupMember.city" />
                                        <label for="inputcity">By</label>
                                    </span>
                                </div>
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText id="inputemail" type="email" class="p-100" v-model="vm.memberGroupMember.email" />
                                        <label for="inputemail">E-mail</label>
                                    </span>
                                    <Message v-if="vm.memberGroupMember.emailHasBounced" class="my-0" severity="error" :closable="false">Afsendelse mislykkes, {{vm.memberGroupMember.emailBounceStatus}}: {{vm.memberGroupMember.emailBounceMessage}}</Message>
                                </div>
                                <div class="col-12">
                                    <span class="p-float-label">
                                        <InputText id="inputphone" type="text" class="p-100" v-model="vm.memberGroupMember.phone" />
                                        <label for="inputphone">Telefon</label>
                                    </span>
                                </div>
                            </div>
                            <div v-if="vm.slideInMemberGroupMemberShowRelations" class="grid mx-0">
                                <div class="col-12 mb-5" :class="{'pl-5 pr-0': vm.slideInMemberGroupMemberShowMember && vm.guardians.length > 0, 'px-0': !vm.slideInMemberGroupMemberShowMember }">
                                    <div v-if="vm.guardians && vm.guardians.length">
                                        <div v-for="(guardian, index) in vm.guardians" :key="index" class="box mt-3 relative">
                                            <Divider class="mb-5">
                                                <h4 class="mb-0">Relation {{ index + 1 }}</h4>
                                            </Divider>
                                            <Button icon="pi pi-trash" class="p-button-remove-guardian p-button-rounded p-button-danger" @click="vm.guardians.splice(index, 1);" />
                                            <div class="grid pl-4">
                                                <div class="col-12">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputpersonlaid_' + index" type="text" class="p-100" v-model="guardian.relatedMember.personalIdEncrypted" v-focus />
                                                        <label :for="'inputpersonlaid_' + index">CPR-nummer</label>
                                                    </span>
                                                </div>
                                                <div class="col-5">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputfirstname_' + index" type="text" class="p-100" v-model="guardian.relatedMember.firstname" />
                                                        <label :for="'inputfirstname_' + index">Fornavn</label>
                                                    </span>
                                                </div>
                                                <div class="col-7">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputlastname_' + index" type="text" class="p-100" v-model="guardian.relatedMember.lastname" />
                                                        <label :for="'inputlastname_' + index">Efternavn</label>
                                                    </span>
                                                </div>
                                                <div class="col-12">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputaddress_' + index" type="text" class="p-100" v-model="guardian.relatedMember.address" />
                                                        <label :for="'inputaddress_' + index">Adresse</label>
                                                    </span>
                                                </div>
                                                <div class="col-3">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputzip_' + index" type="number" class="p-100" v-model="guardian.relatedMember.zip" />
                                                        <label :for="'inputzip_' + index">Postnr</label>
                                                    </span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputcity_' + index" type="text" class="p-100" v-model="guardian.relatedMember.city" />
                                                        <label :for="'inputcity_' + index">By</label>
                                                    </span>
                                                </div>
                                                <div class="col-12">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputemail_' + index" type="text" class="p-100" v-model="guardian.relatedMember.email" />
                                                        <label :for="'inputemail_' + index">E-mail</label>
                                                    </span>
                                                    <Message v-if="guardian.relatedMember.emailHasBounced" class="my-0" severity="error" :closable="false">Afsendelse mislykkes, {{guardian.relatedMember.emailBounceStatus}}: {{guardian.relatedMember.emailBounceMessage}}</Message>
                                                </div>
                                                <div class="col-12">
                                                    <span class="p-float-label">
                                                        <InputText :id="'inputphone_' + index" type="text" class="p-100" v-model="guardian.relatedMember.phone" />
                                                        <label :for="'inputphone_' + index">Telefon</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="my-2">
                                        <strong class="opacity-70">Ingen relationer</strong>
                                    </div>
                                    <Button label="Tilføj relation" class="p-button p-button-sm mt-3" @click="vm.guardians.push({relatedMember:{}})" />
                                </div>
                            </div>
                        </div>
                        <div class="align-self-end mt-auto sticky bottom-spacing">
                            <Button v-if="vm.isMemberGroupMemberEdit" label="Gem" class="p-button-success p-button-lg" @click="fn.createOrUpdateMemberGroupMember" />
                            <Button v-else label="Opret" class="p-button-success p-button-lg" @click="fn.createOrUpdateMemberGroupMember" />
                        </div>
                    </div>
                </Sidebar>
            </div>
        </div>
        <Sidebar v-model:visible="vm.slideInCreateMemberGroup" position="right" class="p-sidebar-md">
            <div class="h-full flex flex-column">
                <h3 v-if="vm.editMemberGroup">Opdatér gruppe</h3>
                <h3 v-else>Opret ny gruppe</h3>
                <div class="box mt-2">
                    <span class="p-float-label">
                        <InputText v-if="vm.editMemberGroup" id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.selectedMemberGroup.name" @keyup.enter="vm.selectedMemberGroup.name && fn.updateMemberGroup({ name: vm.selectedMemberGroup.name })" />
                        <InputText v-else id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="vm.memberGroupName" @keyup.enter="vm.memberGroupName && fn.createMemberGroup()" />
                        <label for="inputname">Gruppenavn</label>
                    </span>
                </div>
                <div class="box mt-2">
                    <span class="p-float-label mb-2">
                        <Calendar v-if="vm.editMemberGroup" inputId="memberGroupExpiresAt" v-model="vm.selectedMemberGroup.expiresAt" :minDate="vm.dateNow" dateFormat="dd-mm-yy" showIcon iconDisplay="input" class="w-full" />
                        <Calendar v-else inputId="memberGroupExpiresAt" v-model="vm.memberGroupExpiresAt" :minDate="vm.dateNow" dateFormat="dd-mm-yy" showIcon iconDisplay="input" class="w-full" />
                        <label for="memberGroupExpiresAt">Slutdato</label>
                    </span>
                    <div class="font-italic">
                        <small>Hvis der er angivet en slutdato, vil gruppen automatisk blive flyttet til papirkurven, når datoen er passeret.</small>
                    </div>
                </div>
                <div class="align-self-end mt-auto sticky bottom-spacing">
                    <Button v-if="vm.editMemberGroup" label="Gem" class="p-button-success p-button-lg" :disabled="!vm.selectedMemberGroup.name" @click="vm.selectedMemberGroup.name && fn.updateMemberGroup({ name: vm.selectedMemberGroup.name, expiresAt: vm.selectedMemberGroup.expiresAt })" />
                    <Button v-else label="Opret" class="p-button-success p-button-lg" :disabled="!vm.memberGroupName" @click="vm.memberGroupName && fn.createMemberGroup()" />
                </div>
            </div>
        </Sidebar>
        <Sidebar v-model:visible="vm.slideInMemberGroupMemberNote" position="right" class="p-sidebar-md">
            <div class="h-full flex flex-column">
                <h3>Noter</h3>
                <div class="box mb-2 relative" v-for="noteDto in vm.memberGroupMember.notes" :key="noteDto.id">
                    <div class="label">
                        {{ $filters.formatDate(noteDto.created) }} ({{ noteDto.systemUser }})
                    </div>
                    <div class="mt-1" style="white-space: pre-line; word-break: break-word;">{{ noteDto.note }}</div>
                    <Button icon="pi pi-trash" class="absolute top-0 right-0" severity="danger" text @click="fn.removeNote(noteDto.id)"></Button>
                </div>
                <div class="box mt-2">
                    <div class="p-float-label">
                        <Textarea rows="5" class="p-100" v-model="vm.memberGroupMemberNote" maxlength="2048" />
                        <label>Ny note</label>
                    </div>
                    <div v-if="remainingCharacters >= 0" class="text-right mb-4">
                        <small>Tegn tilbage: {{ remainingCharacters }}</small>
                    </div>
                    <div class="text-right">
                        <Button label="Tilføj" @click="fn.addNote()"></Button>
                    </div>
                </div>
            </div>
        </Sidebar>
        <ConfirmDialog group="confirmDeleteMember" :style="{'max-width': '600px', width: '100%'}">
            <span v-html="vm.deleteMemberMessage"></span>
            <Message severity="info" :closable="false">Kontakten flyttes til papirkurven, hvor den kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
            <Message severity="warn" :closable="false">Hvis kontakten <u>ikke</u> er i andre grupper, vil alle tilknyttede dokumenter og relationer også blive slettet, og efterfølgende arkiveret jf slettereglerne under "indstillinger".</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteMemberGroup" :style="{'max-width': '600px', width: '100%'}">
            <span v-html="vm.deleteMemberMessage"></span>
            <Message v-if="vm.memberGroupMembers.length > 0" :closable="false"><strong>Vær opmærksom på, at der er kontakter i gruppen</strong><br />Det er kun gruppen, der bliver flyttet til papirkurven, hvor den kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
            <Message v-else severity="info" :closable="false">Gruppen flyttes til papirkurven, hvor den kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteDocument" :style="{'max-width': '600px', width: '100%'}">
            <span v-html="vm.deleteDocumentMessage"></span>
            <Message severity="info" :closable="false">Dokumentet flyttes til papirkurven, hvor det kan gendannes inden for papirkurvens opbevaringsperiode.<br />Efterfølgende bliver det arkiveret jf slettereglerne under "indstillinger".</Message>
        </ConfirmDialog>
        <div v-if="vm.busy" class="overlay overlay-submitting">
            <ProgressSpinner />
        </div>
    </div>
</template>

<script setup>
    import { inject, ref, reactive, computed } from 'vue';
    import api from './../../composables/api';
    import { FilterMatchMode } from 'primevue/api';
    import gqlBuilder from './../../composables/gql-builder';
    import { useToast } from 'primevue/usetoast';
    import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
    import { useConfirm } from "primevue/useconfirm";
    import _ from 'lodash';


    const store = inject('store');
    const apiUrl = inject('apiUrl');
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const confirm = useConfirm();
    const filters = ref({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const expandedRows = ref([]);
    const dt = ref();
    const vm = reactive({
        recivingData: false,
        recivingExtraMemberData: false,
        downloadingDocument: false,
        memberGroups: null,
        memberGroupMembers: [],
        memberGroupMember: {},
        memberGroupMemberNote: '',
        memberGroupName: '',
        memberGroupExpiresAt: null,
        selectedMemberGroup: null,
        slideInCreateMemberGroup: false,
        editMemberGroup: false,
        slideInMemberGroupMember: false,
        isMemberGroupMemberEdit: false,
        secondaryNavIsBusy: false,
        guardians: [],
        expandedRows: [],
        mockContent: new Array(1),
        deleteMemberMessage: '',
        busy: false,
        importResult: {},
        importError: false,
        showMemberDetails: false,
        clonedMember: {},
        menuImportExpanded: false,
        member: { ongoingDocuments: [], completedDocuments: [] },
        dateNow: new Date()
    });

    // Popout menu items
    const memberGroupMenu = ref();
    const memberExtraActions = ref([
        {
            label: 'Se detaljer/rediger',
            icon: 'pi pi-user-edit',
            command: () => {
                fn.goToMemberDetails(vm.memberGroupMember);
            }
        },
        {
            label: 'Deaktivér',
            icon: 'pi pi-history',
            command: () => {
                fn.setActiveState(false);
                vm.visibleMenuId = null;
            },
            visible: () => {
                return vm.selectedMemberGroup && vm.selectedMemberGroup.type !== 'DEFAULT' && vm.memberGroupMember.isActive;
            }
        },
        {
            label: 'Genaktivér',
            icon: 'pi pi-undo',
            command: () => {
                fn.setActiveState(true);
                vm.visibleMenuId = null;
            },
            visible: () => {
                return vm.selectedMemberGroup && vm.selectedMemberGroup.type !== 'DEFAULT' && !vm.memberGroupMember.isActive;
            },
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                fn.confirmDeleteMember(vm.selectedMemberGroup, vm.memberGroupMember);
            }
        }
    ]);
    const memberGroupExtraActions = ref([
        {
            label: 'Rediger',
            icon: 'pi pi-pencil',
            command: () => {
                vm.editMemberGroup = true;
                vm.slideInCreateMemberGroup = true;
            },
        },
        {
            label: 'Deaktivér',
            icon: 'pi pi-history',
            command: () => {
                fn.updateMemberGroup({ isActive: !vm.selectedMemberGroup.isActive });
            },
            visible: () => {
                return vm.selectedMemberGroup.isActive;
            },
        },
        {
            label: 'Genaktivér',
            icon: 'pi pi-undo',
            command: () => {
                fn.updateMemberGroup({ isActive: !vm.selectedMemberGroup.isActive });
            },
            visible: () => {
                return !vm.selectedMemberGroup.isActive;
            },
        },
        {
            label: 'Slet',
            icon: 'pi pi-trash',
            command: () => {
                fn.confirmDeleteMemberGroup(vm.selectedMemberGroup);
            }
        },
    ]);

    const latestNote = computed(() => {
        const notes = vm.memberGroupMember.notes;
        return notes && notes.length ? notes[notes.length - 1] : null;
    });
    const remainingCharacters = computed(() => {
        return 2048 - vm.memberGroupMemberNote.length;
    });

    //// Methods
    const fn = {};

    fn.setActiveState = async (isActive) => {
        vm.busy = true;

        try {
            const res = await api.patch(`${apiUrl}members/${vm.memberGroupMember.id}`, { isActive });

            if (res.status === 200) {
                vm.memberGroupMember.isActive = isActive;
                await syncMemberGroups();
                const message = isActive ? 'Kontakten er genaktiveret' : 'Kontakten er deaktiveret';
                toast.add({ severity: 'success', summary: message, life: 3000 });
            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: `Status code: ${res.status}` });
            }
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error.message });
        } finally {
            vm.busy = false;
        }
    };
    fn.openMemberGroupMemberSidebar = (memberGroupMember, view) => {
        vm.slideInMemberGroupMemberShowMember = true;
        vm.slideInMemberGroupMemberShowRelations = true;

        if (view) {
            vm.slideInMemberGroupMemberShowMember = view === 'MEMBER';
            vm.slideInMemberGroupMemberShowRelations = view === 'MEMBER_RELATIONS';
        }
        vm.isMemberGroupMemberEdit = memberGroupMember ? true : false;
        vm.memberGroupMember = memberGroupMember || { isActive: true };
        vm.clonedMember = _.cloneDeep(memberGroupMember);
        vm.guardians = vm.memberGroupMember.memberRelations || [];
        vm.slideInMemberGroupMember = true;
    };
    fn.goToMemberDetails = async (memberGroupMember) => {
        try {
            vm.memberGroupMember = memberGroupMember;
            vm.showMemberDetails = true;
            vm.recivingExtraMemberData = true;

            const response = await api.get(`${apiUrl}members/${memberGroupMember.id}`);

            vm.memberGroupMember = response || memberGroupMember;
            if (!response) {
                toast.add({ severity: 'error', summary: 'Brugerdata kunne ikke hentes' });
            } else {
                const files = await api.get(`${apiUrl}members/${memberGroupMember.id}/files`);
                const notes = await api.get(`${apiUrl}members/${memberGroupMember.id}/notes`);

                if (files) {
                    vm.memberGroupMember.completedDocuments.push.apply(vm.memberGroupMember.completedDocuments, files);
                }

                vm.memberGroupMember.notes = notes || [];
            }
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        } finally {
            vm.recivingExtraMemberData = false;
        }
    };
    fn.createMemberGroup = async () => {
        try {
            vm.fetchingData = true;

            const response = await api.postJson(`${apiUrl}membergroups`, {
                name: vm.memberGroupName,
                expiresAt: vm.memberGroupExpiresAt
            });

            if (response) {
                toast.add({ severity: 'success', summary: 'Gruppe oprettet', life: 4000 });
                vm.memberGroups.unshift(response);
                router.push({ name: 'AdminMemberId', params: { id: response.id } });

                // Reset form fields
                vm.memberGroupName = '';
                vm.memberGroupExpiresAt = null;
                vm.slideInCreateMemberGroup = false;
            }

        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
        } finally {
            vm.fetchingData = false;
        }
    };
    fn.createOrUpdateMemberGroupMember = () => {
        if (vm.clonedMember) {
            resetEmailBounceStatus(vm.memberGroupMember, vm.clonedMember);
            resetGuardiansEmailBounceStatus(vm.guardians, vm.clonedMember.memberRelations);
        }

        vm.memberGroupMember.memberRelations = vm.guardians;
        vm.memberGroupMember.isAnyMemberRelationEmailEmpty = vm.guardians.some(guardian => !guardian.relatedMember.email);

        addOrUpdateMember();
    };
    fn.getMemberGroupMembers = async (memberGroupId) => {
        vm.recivingData = true;
        vm.selectedMemberGroup = vm.memberGroups.find(mg => mg.id === memberGroupId);

        if (!vm.selectedMemberGroup && vm.memberGroups.length) {
            // If member group is not found, fall back to the first group
            return fn.getMemberGroupMembers(vm.memberGroups[0].id);
        }

        if (vm.selectedMemberGroup) {
            try {
                const response = await api.get(`${apiUrl}membersvalidationresult/${memberGroupId}`);

                if (response) {
                    vm.memberGroupMembers = response;
                }

                vm.showMemberDetails = false;
            } catch (error) {
                console.error('Error fetching member group members:', error);
            } finally {
                vm.recivingData = false;
            }
        }
    };
    fn.onMemberFileUpload = async (member, event) => {
        vm.busy = true;
        try {
            const formData = new FormData();
            formData.append('file', event.files[0]);
            formData.append('note', '');

            const response = await api.postFormData(`${apiUrl}members/${member.id}/files`, formData);

            if (response) {
                vm.memberGroupMember.completedDocuments.push(response);
                toast.add({ severity: 'success', summary: 'Fil uploadet', life: 5000 });
            }

        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl under upload', detail: error.message, closable: true });
        } finally {
            vm.busy = false;
        }
    };
    fn.onImportMembersUpload = async (action, event) => {
        vm.busy = true;

        try {
            const formData = new FormData();
            formData.append('file', event.files[0]);
            formData.append('groupId', vm.selectedMemberGroup.id);

            const response = await api.postFormData(`${apiUrl}customer/${store.auth.customerId}/${action}`, formData);

            vm.importError = false;
            vm.menuImportExpanded = false;

            if (response.message) {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.message, closable: true });
            } else {
                await syncMemberGroups(); // Ensure member group count is updated
                await fn.getMemberGroupMembers(vm.selectedMemberGroup.id); // Update member group members

                vm.importResult = response;
                const detail = vm.importResult.imported.length === 1
                    ? '1 medlem importeret'
                    : `${vm.importResult.imported} medlemmer importeret eller opdateret`;

                vm.importError = (vm.importResult.warnings && vm.importResult.warnings.length) ||
                    (vm.importResult.errors && vm.importResult.errors.length);

                const toastSeverity = vm.importError ? 'warn' : 'success';
                const toastSummary = vm.importError ? 'Gennemført, men med fejl' : 'Gennemført';
                toast.add({ severity: toastSeverity, summary: toastSummary, detail: detail, life: 5000 });
            }
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl under upload', detail: error.message, closable: true });
        } finally {
            vm.busy = false;
        }
    };
    fn.updateMemberGroup = async (data) => {
        try {
            const res = await api.patch(`${apiUrl}membergroups/${vm.selectedMemberGroup.id}`, data);

            if (res.status === 200) {
                if (data.name) {
                    toast.add({ severity: 'success', summary: 'Gruppe opdateret', life: 3000 });
                    vm.slideInCreateMemberGroup = false;
                } else if (data.isActive) {
                    vm.selectedMemberGroup.isActive = data.isActive;
                    toast.add({ severity: 'success', summary: 'Gruppe genaktiveret', life: 3000 });
                } else {
                    vm.selectedMemberGroup.isActive = data.isActive;
                    toast.add({ severity: 'success', summary: 'Gruppe deaktiveret', life: 3000 });
                }
                vm.visibleMemberGroupId = null;
                await syncMemberGroups();
            } else {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: 'Prøv igen' });
            }
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error.message });
        }
    };
    fn.showCreateMemberGroup = () => {
        vm.editMemberGroup = false;
        vm.slideInCreateMemberGroup = true;
    }
    fn.addNote = () => {
        if (vm.memberGroupMemberNote) {
            api.postJson(`${apiUrl}members/${vm.memberGroupMember.id}/notes`, { note: vm.memberGroupMemberNote }).then((note) => {
                if (note) {
                    vm.memberGroupMember.notes.push(note);
                    vm.memberGroupMemberNote = '';
                    toast.add({ severity: 'success', summary: 'Note oprettet', life: 3000 });

                }
            });
        }
    }
    fn.removeNote = (noteId) => {
        if (noteId) {
            api.del(`${apiUrl}members/${vm.memberGroupMember.id}/notes/${noteId}`).then(() => {
                vm.memberGroupMember.notes = vm.memberGroupMember.notes.filter(note => note.id !== noteId);
                toast.add({ severity: 'success', summary: 'Note slettet', life: 3000 });
            });
        }
    }


    // Documents
    fn.downloadDocumentAsset = (documentAsset) => {
        var link = document.createElement('a');
        link.href = documentAsset.url;
        link.download = documentAsset.title;
        link.click();
    };
    fn.downloadDocument = (doc) => {
        var link = document.createElement('a');
        link.href = getDocumentFileUrl(doc);
        link.download = doc.title ? doc.title : (doc.documentTemplateName + '_' + doc.fileRef.id + ".pdf");

        link.click();
    };
    fn.downloadMergedDocument = async (doc) => {
        vm.downloadingDocument = true;

        try {
            const response = await api.postJson(`${apiUrl}documents/${doc.id}?action=mergeDocumentAndAssets`, {});
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${doc.documentTemplateName}_${doc.fileRef.id}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error.message });
        } finally {
            vm.downloadingDocument = false;
        }
    };
    fn.goToDocument = (doc) => {
        window.open(getDocumentFileUrl(doc), '_blank');
    };
    fn.confirmDeleteDocument = (doc) => {
        const deleteApiUrl = doc && doc.documentTemplateName
            ? `${apiUrl}documents/${doc.id}`
            : `${apiUrl}members/${vm.memberGroupMember.id}/files/${doc.id}`;

        vm.deleteDocumentMessage = `Er du sikker på, at du vil slette dokumentet <strong>${doc.documentTemplateName}</strong>?`;

        confirm.require({
            group: 'confirmDeleteDocument',
            header: 'Bekræft slet dokument',
            acceptLabel: 'Ja, slet',
            acceptClass: 'p-button-success p-button-lg',
            rejectLabel: 'Nej, luk',
            rejectClass: 'p-button-link p-button-lg',
            accept: async () => {
                try {
                    vm.deletingDocument = true;

                    const response = await api.del(deleteApiUrl);

                    if (response) {
                        // Filter out the deleted document from pending and completed lists
                        ['pendingDocuments', 'completedDocuments'].forEach((list) => {
                            if (vm.memberGroupMember[list]?.length) {
                                vm.memberGroupMember[list] = vm.memberGroupMember[list].filter(d => d.id !== doc.id);
                            }
                        });

                        toast.add({
                            severity: 'success',
                            summary: 'Dokument slettet',
                            detail: doc.documentTemplateName,
                            life: 3000
                        });
                    } else {
                        const errorMsg = response.errors?.[0]?.message || 'Ukendt fejl';
                        toast.add({
                            severity: 'error',
                            summary: 'Der skete en fejl',
                            detail: errorMsg
                        });
                    }
                } catch (error) {
                    toast.add({
                        severity: 'error',
                        summary: 'Der skete en fejl',
                        detail: error,
                        life: 10000
                    });
                } finally {
                    vm.deletingDocument = false;
                }
            }
        });
    };


    // Remove/delete
    fn.confirmDeleteMember = (memberGroup, member) => {
        vm.deleteMemberMessage = 'Er du sikker på, at du vil slette <strong>' + member.firstname + ' ' + member.lastname + '</strong>?';
        confirm.require({
            group: 'confirmDeleteMember',
            header: 'Bekræft slet kontakt',
            acceptLabel: 'Ja, slet',
            acceptClass: 'p-button-success p-button-lg',
            rejectLabel: 'Nej, luk',
            rejectClass: 'p-button-link p-button-lg',
            accept: () => {
                vm.busy = true;
                api.del(apiUrl + 'memberGroups/' + memberGroup.id + '/members/' + member.id).then(() => {
                    toast.add({ severity: 'success', summary: 'Gennemført', detail: member.firstname + ' ' + member.lastname + ' slettet', life: 4000 });
                    syncMemberGroups();
                    vm.memberGroupMembers = vm.memberGroupMembers.filter(val => val.id !== member.id);
                    vm.memberGroups.map(item => {
                        item.members = item.members.filter(m => m.id !== member.id);
                        return item;
                    });
                    vm.busy = false;
                    vm.deleteMemberMessage = '';
                }).catch((error) => {
                    toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                    vm.busy = false;
                });
            }
        });
    };
    fn.confirmDeleteMemberGroup = (memberGroup) => {
        vm.deleteMemberMessage = 'Er du sikker på, at du vil slette gruppen: <strong>' + memberGroup.name + '</strong>'
        confirm.require({
            group: 'confirmDeleteMemberGroup',
            header: 'Bekræft slet gruppe',
            acceptLabel: 'Ja, slet',
            acceptClass: 'p-button-success p-button-lg',
            rejectLabel: 'Nej, luk',
            rejectClass: 'p-button-link p-button-lg',
            accept: () => {
                vm.busy = true;

                api.del(apiUrl + 'memberGroups/' + memberGroup.id).then(() => {
                    toast.add({ severity: 'success', summary: 'Gennemført', detail: memberGroup.name + ' slettet', life: 4000 });
                    syncMemberGroups(() => {
                        if (vm.memberGroups && vm.memberGroups.length) {
                            router.push({ name: 'AdminMemberId', params: { id: vm.memberGroups[0].id } });
                        } else {
                            vm.selectedMemberGroup = null;
                            router.push({ name: 'AdminMembers' });
                        }
                    });

                    vm.busy = false;
                    vm.deleteMemberMessage = '';
                }).catch((error) => {
                    toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                    vm.busy = false;
                });
            }
        });
    };

    // Utillities/toggles
    fn.rowClass = (rowData) => {
        return rowData.documentAssets && rowData.documentAssets.length ? "" : "no-expander";
    };
    fn.toggleMenuImport = () => {
        vm.menuImportExpanded = !vm.menuImportExpanded;
    };
    fn.toggleMemberActions = (event, data) => {
        vm.memberGroupMember = data;
        vm.visibleMenuId = vm.memberGroupMember.id === vm.visibleMenuId ? null : vm.memberGroupMember.id;
    };
    fn.toggleMemberGroupMenu = (event, memberGroupId) => {
        vm.visibleMemberGroupId = memberGroupId === vm.visibleMemberGroupId ? null : memberGroupId;
    };
    fn.exportMembersInMemberGroup = (memberGroup) => {
        if (memberGroup) {
            window.open(apiUrl + 'membergroups/' + memberGroup.id + '?action=download', '_blank');
        }
    };

    // Router
    onBeforeRouteUpdate((to, from, next) => {
        if (to.params && to.params.id) {
            fn.getMemberGroupMembers(to.params.id);
        }

        next();
    });

    // Helpers
    function init() {
        syncMemberGroups(() => {
            if (vm.memberGroups.length) {
                fn.getMemberGroupMembers(route.params && route.params.id ? route.params.id : vm.memberGroups[0].id);
            }
        })
    }
    function syncMemberGroups(callback) {
        vm.secondaryNavIsBusy = true;

        // TODO: REST endpoint
        api.query(gqlBuilder.memberGroups.get(store.auth.customerId)).then((response) => {
            if (api.hasData(response)) {
                vm.secondaryNavIsBusy = false;
                const memberGroups = api.getData(response);

                memberGroups.forEach(function (group) {
                    group.activeMembersCount = group.members.filter(m => m.isActive).length || 0;
                    group.inactiveMembersCount = group.members.length - group.activeMembersCount || 0;
                });
                vm.memberGroups = memberGroups;
                if (callback) {
                    callback();
                }
            }
        }).catch((error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        });
    }

    function addOrUpdateMember() {
        const handleSuccess = (message) => {
            toast.add({ severity: 'success', summary: message, life: 3000 });
            vm.slideInMemberGroupMember = false;
        };

        const handleError = (error) => {
            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
        };

        const updateMemberList = (member) => {
            const index = vm.memberGroupMembers.findIndex(m => m.id === member.id);
            if (index !== -1) {
                vm.memberGroupMembers.splice(index, 1, member);
            } else {
                vm.memberGroupMembers.push(member);
            }
        };

        const updateMember = () => {
            api.put(apiUrl + 'members/' + vm.memberGroupMember.id, vm.memberGroupMember)
                .then(() => {
                    updateMemberList(vm.memberGroupMember);
                    handleSuccess('Medlem opdateret');
                })
                .catch(handleError);
        };

        const addNewMember = () => {
            api.postJson(apiUrl + 'members/', vm.memberGroupMember)
                .then((res) => {
                    if (res) {
                        vm.memberGroupMember = res;
                        updateMemberList(res);

                        return api.postJson(apiUrl + 'membergroups/' + vm.selectedMemberGroup.id + '/members', vm.memberGroupMember);
                    }
                })
                .then(() => {
                    const message = vm.guardians.length > 0 ? 'Medlem og relation(er) oprettet' : 'Medlem oprettet';
                    handleSuccess(message);
                    syncMemberGroups();
                })
                .catch(handleError);
        };

        // Existing member, update
        if (vm.memberGroupMember.id) {
            updateMember();
        } else {
            // New member, add
            addNewMember();
        }
    }

    function getDocumentFileUrl(doc) {
        if (doc && doc.url) {
            return doc.url;
        }
        if (doc && doc.fileRef && doc.fileRef.url) {
            return doc.fileRef.url;
        }

        // Old files
        return `${apiUrl}document/${store.auth.customerId}/file/${doc.id}`
    }

    // Helper function to reset email bounce status for the member
    function resetEmailBounceStatus(member, clonedMember) {
        if (clonedMember.email && member.email !== clonedMember.email) {
            member.emailBounceMessage = null;
            member.emailBounceStatus = null;
            member.emailHasBounced = false;
        }
    }

    // Helper function to reset email bounce status for guardians
    function resetGuardiansEmailBounceStatus(guardians, clonedRelations) {
        guardians.forEach((guardian, index) => {
            if (
                guardian &&
                clonedRelations[index] &&
                guardian.relatedMember.email !== clonedRelations[index].relatedMember.email
            ) {
                guardian.relatedMember.emailBounceMessage = null;
                guardian.relatedMember.emailBounceStatus = null;
                guardian.relatedMember.emailHasBounced = false;
            }
        });
    }

    // Init
    init();


</script>
<style scoped>
    .p-button-remove-guardian {
        position: absolute;
        right: -0.5rem;
        top: -0.5rem;
    }

    .members {
        flex: 1 1 auto;
        overflow-x: auto;
        padding-bottom: 10rem;
    }


    .member-details h4 {
        /* border-left: 4px solid #39bc6c;*/
        padding-left: 0.25rem;
    }


    .label {
        color: rgba(0, 0, 0, 0.4);
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
    }
</style>
<style>
    .member-files .p-datatable .p-datatable-tbody > tr.no-expander > td .p-row-toggler {
        display: none;
    }

    .expander,
    .member-files > .p-datatable .p-datatable-tbody > tr.no-expander > td:first-child {
        width: 0px !important;
        padding: 0 !important;
    }

    .p-menubar {
        background-color: #fff;
    }

        .p-menubar .p-menubar-root-list {
            display: flex !important;
        }

        .p-menubar .p-button-label {
            font-weight: normal;
        }

        .p-menubar .p-submenu-list .p-button, .p-menubar .p-submenu-list .p-button-label, .p-menubar .p-submenu-list .p-button-icon {
            background: white !important;
            border: 0;
            font-weight: normal;
            color: #495057;
            text-align: left;
        }

    .member-file-uploader .p-button {
        color: #4849a1;
        background: none !important;
        border: 0 !important;
        font-size: 0.875rem;
    }
        .member-file-uploader .p-button:hover {
            color: #4849a1;
            background: none !important;
            border: 0 !important;
            text-decoration:underline;
        }
</style>