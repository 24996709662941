<template>
    <div class="component-upload relative my-5 mx-3">
        <h3>{{props.headline}}<span v-if="props.required"> *</span></h3>
        <div v-if="props.description" v-html="props.description"></div>

        <div v-if="props.fileLimit === 1" class="mb-2">
            <div v-if="props.lang === 'da'">
                Du kan kun uploade {{props.fileLimit}} fil.
            </div>
            <div v-else>
                You can only upload {{props.fileLimit}} file.
            </div>
        </div>
        <div v-if="props.fileLimit > 1" class="mb-2">
            <div v-if="props.lang === 'da'">
                Du kan uploade op til {{props.fileLimit}} filer i alt.
            </div>
            <div v-else>
                You can upload a maximum of {{props.fileLimit}} files in total.
            </div>
        </div>
        <div v-if="props.assets && props.assets.length" class="mb-2">
            <p class="mb-1">Uploadede filer</p>
            <div v-for="asset in props.assets" :key="asset.id" class="font-bold">
                {{asset.title}}
            </div>
        </div>
        <div v-if="!props.assets || props.fileLimit > props.assets.length">
            <FileUpload name="upload[]" @select="onSelectedFiles" @remove="onRemove" @clear="onRemove" customUpload :multiple="props.fileLimit > 1" :fileLimit="fileLimit" :accept="props.accept" :maxFileSize="props.maxFileSize" :showUploadButton="false">
                <template #empty>
                    <p>Træk og slip filer her for at uploade.</p>
                </template>
            </FileUpload>
            <input type="file" class="absolute bottom-0" style="z-index:-1" :name="props.name" :required="props.required" :multiple="props.fileLimit > 1" />
        </div>

    </div>
</template>


<script setup>
    import { onMounted, defineProps, computed } from 'vue'

    let form;
    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        headline: {
            type: String,
            default: '',
            required: false
        },
        description: {
            type: String,
            default: '',
            required: false
        },
        maxFileSize: {
            type: Number,
            default: 10000000
        },
        fileLimit: {
            type: Number,
            default: 1
        },
        accept: {
            type: String,
            default: 'application/pdf,image/*'
        },
        assets: {
            type: Array
        },
        required: {
            type: Boolean
        },
        lang: {
            type: String
        }
    });

    function onSelectedFiles(obj) {
        var fileInput = form.querySelector('input[name="' + props.name + '"]');

        if (fileInput && obj && obj.files) {
            var fileList = new DataTransfer();
            
            obj.files.forEach(function (file) {
                fileList.items.add(file);
            });

            fileInput.files = fileList.files;

        } else {
            fileInput.value = '';
        }
    }

    function onRemove(file) {
        onSelectedFiles({ files: file && file.files || [] });
    }

    onMounted(() => {
        form = document.querySelector('form');
    });

    const fileLimit = computed(() => {
        if (props.assets) {
            return props.fileLimit - props.assets.length;
        }

        return props.fileLimit;
    });

</script>