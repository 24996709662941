<template>
    <div class="half-doughnut">
        <Chart class="chart" type="doughnut" :data="vm.data" :options="vm.options" />
        <div class="percentage">{{vm.data.datasets[0].percentage}}%</div>
    </div>
</template>

<script>

    import { defineComponent, reactive, onMounted } from 'vue';


    export default defineComponent({
        name: 'StatsHalfDoughnut',
        props: {
            completed: Number,
            declined: Number,
            total: Number
        },
        setup(props) {

            const vm = reactive({
                data: {
                    datasets: [
                        {
                            percentage: 0,
                            data: [0, 0, 0],
                            backgroundColor: ['#39bc6c', '#EF4444', '#d3d3d3'],
                            hoverBackgroundColor: ['#61c989', '#dc5959', '#d3d3d3']
                        }
                    ]
                },
                options: {
                    rotation: -90,
                    circumference: 180,
                    borderWidth: 4,
                    borderRadius: 6,
                    cutout: '60%',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    var type = 'Afventer';
                                    if (context.dataIndex === 0) {
                                        type = 'Udfyldt';
                                    } else if (context.dataIndex === 1){
                                        type = 'Afvist';
                                    }
                                    return type + ': ' + context.parsed + (context.parsed > 1 ? ' elever' : ' elev');
                                }
                            }
                        }
                    },
                }
            });


            const fn = {

            };

            onMounted(() => {
                if (props && props.total) {
                    vm.data.datasets[0].data[0] = Math.round(props.completed);
                    vm.data.datasets[0].data[1] = Math.round(props.declined);
                    vm.data.datasets[0].data[2] = Math.round(props.total - (props.declined + props.completed));
                    vm.data.datasets[0].percentage = Math.ceil((props.completed + props.declined) / props.total * 100);
                }
            });

            return { vm, fn };
        }
    });
</script>
<style scoped>
    .half-doughnut {
        position: relative;
    }

        .half-doughnut .percentage {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20%;
            font-size: 2rem;
            font-family: "Figtree", sans-serif;
            font-weight:bold;
            color: #495057;
        }

        .half-doughnut .chart {
            max-width: 400px;
            margin: -3rem auto -2.5rem;
            padding: 0 1rem;
        }
</style>
