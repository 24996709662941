export function handlePastePreprocess(plugin, args) {
    let content = args.content;

    // Remove Word-specific markup
    content = content.replace(/<!--\[if[^>]*>.*?<!\[endif\]-->/gi, '');  // Remove conditional comments
    content = content.replace(/<\/?o:[^>]*>/gi, '');  // Remove Office namespace tags
    content = content.replace(/<\/?v:[^>]*>/gi, '');  // Remove VML tags
    content = content.replace(/<\/?w:[^>]*>/gi, '');  // Remove Word tags
    content = content.replace(/<\/?m:[^>]*>/gi, '');  // Remove Math tags

    // Remove Word-specific styles
    content = content.replace(/style="[^"]*mso-[^"]*"/gi, '');  // Remove inline styles with mso- prefix

    // Remove font-family and font-size styles
    content = content.replace(/font-family:[^;"]*;?/gi, '');  // Remove font-family styles
    content = content.replace(/font-size:[^;"]*;?/gi, '');    // Remove font-size styles

    args.content = content;
}