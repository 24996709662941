<template>
    <div class="component-pdf-viewer my-5 text-center">
        <div v-if="vm.loading">
            <i class="pi pi-spin pi-spinner"></i>
            <p>Henter dokument</p>
        </div>
        <object v-if="vm.loadObjectViewer" :data="vm.url" width="100%" height="100%" @load="onObjectLoad">
            <Button label="Vis dokument" class="mt-3" @click="fn.loadFallback"></Button>
        </object>
        <div v-if="vm.loadFallback">
            <div v-if="vm.totalPages > 1" class="align-items-center border-round flex justify-content-center mb-2" style="background: #f3f4ff;">
                <Button icon="pi pi-chevron-left" class="p-button-link" :disabled="vm.currentPageNum === 1" @click="fn.prev"></Button>
                <div class="mx-3 text-base">Side {{ vm.currentPageNum + '/' + vm.totalPages }}</div>
                <Button icon="pi pi-chevron-right" class="p-button-link" iconPos="right" :disabled="vm.currentPageNum === vm.totalPages" @click="fn.next"></Button>
            </div>
            <canvas class="canvas" id="preview-canvas"></canvas>
        </div>
    </div>
</template>

<script setup>
    import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
    import { reactive, onMounted, defineProps } from 'vue';

    const props = defineProps({
        url: String
    });


    pdfjsLib.GlobalWorkerOptions.workerSrc = "/libs/pdf/pdf.worker.min.mjs?v=4.7.76";

    const vm = reactive({
        url: props.url,
        loading: false,
        totalPages: 0,
        currentPageNum: 0,
        loadFallback: false,
        loadObjectViewer: false
    });

    let pdfDocPromise = null,
        pdfDoc = null,
        pageNum = 1,
        pageRendering = false,
        pageNumPending = null,
        scale = 0.99,
        canvas, ctx;

    const fn = {
        loadFallback() {
            vm.loading = true;
            vm.loadObjectViewer = false;
            vm.loadFallback = true;

            pdfDocPromise.then((data) => {
                pdfDoc = data;
                vm.totalPages = pdfDoc.numPages;
                canvas = document.getElementById('preview-canvas');

                if (canvas) {
                    ctx = canvas.getContext('2d');
                    renderPage(pageNum);
                }
            });
        },
        prev() {
            if (pageNum <= 1) return;
            pageNum--;
            queueRenderPage(pageNum);
        },
        next() {
            if (pageNum >= pdfDoc.numPages) return;
            pageNum++;
            queueRenderPage(pageNum);
        }
    };

    onMounted(() => {
        // IOS devices returns yes to pdfViewerEnabled but cant display a pdf inline
        if (navigator.pdfViewerEnabled && !isIOS()) {
            vm.loading = true;
            vm.loadObjectViewer = true;
        } else {
            pdfDocPromise = pdfjsLib.getDocument({ url: vm.url, withCredentials: true }).promise;
            fn.loadFallback();
        }
    });

    function renderPage(num) {
        pageRendering = true;
        pdfDoc.getPage(num).then((page) => {
            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: ctx,
                viewport
            };
            const renderTask = page.render(renderContext);

            renderTask.promise.then(() => {
                pageRendering = false;
                vm.loading = false;
                if (pageNumPending !== null) {
                    renderPage(pageNumPending);
                    pageNumPending = null;
                }
            });
        });

        vm.currentPageNum = num;
    }

    function queueRenderPage(num) {
        if (pageRendering) {
            pageNumPending = num;
        } else {
            renderPage(num);
        }
    }

    function isIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) ||
            (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    function onObjectLoad() {
        vm.loading = false; 
    }
</script>

<style scoped lang="scss">
.pi-spinner {
    font-size: 50px;
    color: #39bc6c;
    animation: fa-spin 1s infinite linear;
}
.canvas {
    max-width: 100%;
}
</style>
